import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from '../../../../../common/confirmation-modal';
import { IDeleteProfileModal } from './delete-profile-modal.models';

export const DeleteProfileModal = ({
  step,
  ...props
}: IDeleteProfileModal): JSX.Element => (
  <ConfirmationModal
    {...props}
    hasChangedButtonsPriority
    title={<FormattedMessage id={`profileDeletion.${step}.modal.title`} />}
    content={<FormattedMessage id={`profileDeletion.${step}.modal.description`} />}
    confirmButtonLabel={
      <FormattedMessage id={`profileDeletion.${step}.modal.confirmButton.label`} />
    }
    rejectButtonLabel={
      <FormattedMessage id={`profileDeletion.${step}.modal.rejectButton.label`} />
    }
  />
);
