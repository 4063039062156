import { useReactiveVar } from '@apollo/client';

import { ReactComponent as QRCode } from '../../../../../../assets/images/qr-code.svg';
import { ReactComponent as AppStore } from '../../../../../../assets/images/app-store.svg';
import { ReactComponent as GooglePlay } from '../../../../../../assets/images/google-play.svg';

import { useStyles } from './download-app.styles';
import { ExternalLinks } from '../../../../../../constants';
import { CampaignIcon } from '../campaign-icon';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../../graphql/configuration/configuration.cache';

export const DownloadApp = (): JSX.Element => {
  const classes = useStyles();
  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <div
      className={classes.apps}
      data-testid="download-app"
    >
      <div className={classes.stores}>
        <a
          href={ExternalLinks.mobileMarket}
          target="_blank"
          data-testid="appStore-link"
        >
          <AppStore className={classes.appStore} />
        </a>
        <a
          href={ExternalLinks.mobileMarket}
          target="_blank"
          data-testid="googlePlay-link"
        >
          <GooglePlay className={classes.googlePlay} />
        </a>
      </div>
      <div>
        <QRCode
          className={classes.qrCode}
          data-testid="qrCode"
        />
      </div>
      {isCampaignIcon && <CampaignIcon />}
    </div>
  );
};
