import { FormattedMessage } from 'react-intl';

import { ITitleSize, UserActivitiesList } from '../../../../../common/user-activities-list';
import { PromotionCard, PromotionsPlaceholder } from '../../../user-activity-items/promotions';
import { IPromotionsListProps } from './promotions-list.models';
import { PromotionType } from '../../../../../../graphql/promotions/models/get-promotions.models';
import { replaceWelcomeVoucher } from '../../../my-bonuses/utils/replace-welcome-voucher';

export const PromotionsList = ({ promotions }: IPromotionsListProps): JSX.Element => {
  const firstVoucher = promotions.find((
    promotion,
  ) => promotion.type === PromotionType.discountFirstPurchase);

  return (
    <UserActivitiesList
      title={{
        size: ITitleSize.lg,
        label: (
          <FormattedMessage
            id="promotions.title"
          />
        ),
      }}
      activities={firstVoucher ? replaceWelcomeVoucher(promotions)
        .map((promotion) => (
          <PromotionCard
            id={`${promotion?.data.title}${promotion?.data.endDate}`}
            promotion={promotion!}
            isAvailable={!(promotion?.type === PromotionType.discountSecondPurchase)}
            isFirstWelcomeVoucher={promotion?.type === PromotionType.discountFirstPurchase}
          />
        )) : promotions
        .map((promotion) => (
          <PromotionCard
            id={`${promotion?.data.title}${promotion?.data.endDate}`}
            promotion={promotion}
            notClickable={promotion?.type === PromotionType.discountSecondPurchase}
          />
        ))
      }
      placeholder={<PromotionsPlaceholder/>}
    />
  );
};
