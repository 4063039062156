import { SyntheticEvent, useCallback } from 'react';
import { Link } from 'react-router-dom';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
} from '@mui/material';
import { cx } from '@emotion/css';

import { IMenuProps, IAdditionalMenuItemProps } from './dropdown-menu.models';
import { useStyles } from './dropdown-menu.styles';
import { Icon, IconTypes } from '../icon';

export const DropdownMenu = (props: IMenuProps) => {
  const classes = useStyles();
  const {
    items, anchorEl, onClick, onClose,
  } = props;
  const handleClick = useCallback((event: SyntheticEvent) => {
    onClick(event.currentTarget.getAttribute('data-value'));
  }, [onClick]);

  return (
    <MuiMenu
      data-testid="dropdown-menu"
      keepMounted
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      elevation={0}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        paper: classes.menuPaper,
      }}
      MenuListProps={{
        classes: {
          root: classes.menuBox,
        },
      }}
    >
      {items.map(({
        name, icon, value, to, href, target, hidden, state,
      }, index) => {
        const additionalProps: IAdditionalMenuItemProps = {};

        if (to) {
          additionalProps.component = Link;
          additionalProps.to = to;
          additionalProps.state = state; 
        } else if (href) {
          additionalProps.component = 'a';
          additionalProps.href = href;
          additionalProps.target = target;
        }

        return (
          <MuiMenuItem
            {...additionalProps}
            onClick={handleClick}
            key={index}
            data-value={value}
            classes={{
              root: cx(classes.menuListItem,
                { [classes.listItemHidden]: hidden }),
            }}
            data-testid="dropdown-menu-item"
          >
            <MuiListItemIcon classes={{ root: classes.menuItemIconRoot }}>
              <Icon type={IconTypes[icon]} />
            </MuiListItemIcon>
            <MuiListItemText classes={{ root: classes.menuInnerText }} primary={name} />
          </MuiMenuItem>
        );
      })}
    </MuiMenu>
  );
};
