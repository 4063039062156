import { gql } from '@apollo/client';

export const GET_BONUSES_PREVIEW = gql`
  query GetBonusesPreviewQuery {
    pointByAuthId {
      id
      eligibleBalance
    }
    getDynamicComponents {
      id
      type
      data {
        title
      }
    }
  }
`;
