import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({
  breakpoints, palette, fontFamilies, fontSizes, fontWeights, lineHeights,
}: Theme) => ({
  surveyTitle: {
    margin: '0 0 12px',
    textAlign: 'center',
    color: palette.text.primary,
    fontSize: fontSizes.xs,
    fontWeight: fontWeights.regular,
    lineHeight: lineHeights.sm,
    fontFamily: fontFamilies.primary,
    [breakpoints.up('md')]: {
      margin: '0 0 20px',
      fontSize: fontSizes.sm,
      lineHeight: lineHeights.md,
    },
  },
}));
