import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  PromotionType,
} from '../../../../../../../../graphql/promotions/models/get-promotions.models';
import { IPromotions } from './promotions.models';
import {
  FIRST_WELCOME_VOUCHER_VALUE,
  SECOND_WELCOME_VOUCHER_VALUE,
} from '../../../../../../../../constants';
import { useStyles } from './promotions.styles';


export const Promotions = ({ promotions }: IPromotions): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const promotionsAmount = promotions.length;

  const isFirstVoucher = promotions.length === 1
    && promotions[0].type === PromotionType.discountFirstPurchase;
  const isSecondVoucher = promotions.length === 1
    && promotions[0].type === PromotionType.discountSecondPurchase;
  const isWelcomeVouchers = isFirstVoucher || isSecondVoucher;

  const firstVoucherValue = FIRST_WELCOME_VOUCHER_VALUE + SECOND_WELCOME_VOUCHER_VALUE;

  const WelcomeAmount = () => (
    <>
      {intl.formatMessage(
        { id: 'myPointsPreview.bonuses.promotions.amount' },
        {
          value: isFirstVoucher ? firstVoucherValue : SECOND_WELCOME_VOUCHER_VALUE,
          span: (chunks: ReactNode) => (
            <span
              className={classes.emphasis}
              data-testid="emphasis"
            >
              {chunks}
            </span>
          ),
        },
      )}
    </>
  );

  return (
    <FormattedMessage
      id="myPointsPreview.bonuses.promotions"
      values={{ amount: isWelcomeVouchers ? <WelcomeAmount /> : promotionsAmount }}
    />
  );
};
