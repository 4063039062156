import { gql } from '@apollo/client';

export const GET_FITTED_LENSES = gql`
  query GetFittedLensesQuery {
    consumerLensesByAuthID {
      id
      createdManual
      brandId
      brand {
        fullName
        points {
          packSize
          acuvuePoints
        }
      }
      leftEye {
        value
        property
      }
      rightEye {
        value
        property
      }
      loyaltyPoints {
        brandId
        packSize
        value
      }
    }
  }
`;
