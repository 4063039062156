import { FormattedMessage, useIntl } from 'react-intl';

import { Preloader } from '../../../../common/preloader';
import { Card, CardTypes } from '../../../../common/card';
import { Button, Icon, IconTypes } from '../../../../common';
import { IAppointmentProps } from './appointment.models';
import { useStyles } from './appointment.styles';
import { InternalLinks } from '../../../../../constants';
import { useGoBack } from '../../../../../hooks/use-go-back';
import {
  isAppointmentContentShow,
} from '../../../../../utils/appointment/is-appoinment-content-show';
import {
  AppointmentStatusType,
  AppointmentType,
} from '../../../../../graphql/appointments/models/get-appointments.models';
import { isClinicStore } from '../../../../../utils/appointment/define-store-types';

export const Appointment = ({
  appointments, isLoading, isError,
}: IAppointmentProps): JSX.Element | null => {
  const intl = useIntl();
  const classes = useStyles();
  const { hasPreviousLocationState } = useGoBack();

  const renderContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    const { allAppointmentsByAuthId: { edges } } = appointments!;

    if (edges.length) {
      const {
        node: {
          date,
          status,
          time,
          appointmentType,
          store: {
            displayName,
            storeType,
            storeSubType,
          },
        },
      } = edges[0];

      const isAppointmentShow = isAppointmentContentShow(date, status);
      const isAppointmentConfirmed = status === AppointmentStatusType.confirmed;
      const confirmedTitle = isClinicStore(storeSubType || storeType)
        ? <FormattedMessage id="appointment.landing.confirmed.clinic" />
        : <FormattedMessage id="appointment.landing.confirmed.optics" />;

      return (
        <>
          {isAppointmentShow && (
            <Card
              className={classes.root}
              type={CardTypes.tertiary}
              data-testid="appointment-landing-card"
            >
              <div
                className={classes.content}
                data-testid="content"
              >
                <p
                  data-testid={isAppointmentConfirmed
                    ? 'appointment-status-confirmed'
                    : 'appointment-status-unconfirmed'}
                  className={classes.status}
                >
                    <span
                      className={classes.statusIcon}
                      data-testid="appointment-status-icon"
                      aria-hidden="true"
                    >
                      {isAppointmentConfirmed ? (
                        <Icon
                          type={IconTypes.checked}
                        />
                      ) : (
                        <Icon
                          type={IconTypes.clockBlack}
                        />
                      )}
                    </span>
                  <span data-testid="appointment-status-text">
                    {isAppointmentConfirmed ? confirmedTitle : (
                      <FormattedMessage id="appointment.landing.unconfirmed" />
                    )}
                  </span>
                </p>
                <h2
                  data-testid="appointment-landing-title"
                  className={classes.title}
                >
                  {appointmentType === AppointmentType.controlVisit ? (
                    <FormattedMessage id="appointment.landing.title.controlVisit" />
                  ) : (
                    <FormattedMessage id="appointment.landing.title.fitting" />
                  )}
                </h2>
                <p
                  data-testid="appointment-landing-name"
                  className={classes.paragraph}
                >
                  {displayName}
                </p>
              </div>
              <div
                data-testid="appointment-show-button-wrapper"
                className={classes.showButtonWrapper}
              >
                <Button
                  data-testid="appointment-show-button"
                  variant="text"
                  to={InternalLinks.appointment}
                  state={hasPreviousLocationState}
                  className={classes.showButton}
                >
                  <p
                    data-testid="appointment-landing-date"
                    className={classes.dateWrapper}
                  >
                    <span
                      data-testid="month"
                      className={classes.month}
                    >
                      {new Date(date).toLocaleDateString(intl.locale, {
                        month: 'long',
                      })}
                    </span>
                    <span
                      data-testid="day"
                      className={classes.day}
                    >
                      {new Date(date).toLocaleDateString(intl.locale, {
                        day: 'numeric',
                      })}
                    </span>
                    {time && (
                      <span data-testid="time">
                        {time}
                      </span>
                    )}
                  </p>
                </Button>
              </div>
            </Card>
          )}
        </>
      );
    }

    return null;
  };

  return appointments?.allAppointmentsByAuthId ? renderContent() : null;
};
