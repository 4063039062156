export const ACCOUNT_BLOCKED_MESSAGE = 'Account is blocked';
export const INVALID_ACCESS_TOKEN_MESSAGE = 'Invalid access token';
export const ACCESS_TOKEN_EXPIRED_MESSAGE = 'Access Token expired';
export const PIN_CODE_BLOCKED = 'PIN_CODE_BLOCKED';
export const CONSUMER_NOT_FOUND = 'CONSUMER_NOT_FOUND';
export const BONUS_RULES = 'SY.VE_PACKS_COUNT_IS_NOT_SATISFIED';
export const FREE_PACK_RULES = 'SY.VE_FREE_PACK_PRODUCT_NOT_FOUND';
export const CUSTOM_FREE_PACK_IS_ABSENT = 'SY.VE_CUSTOM_FREE_PACK_IS_ABSENT_IN_PURCHASE';
export const INVALID_RECAPTCHA = 'INVALID_RECAPTCHA';
export const CONSUMER_ALREADY_EXIST = 'CONSUMER_ALREADY_EXIST';
export const RECAPTCHA_REQUEST_DENIED = 'RECAPTCHA_REQUEST_DENIED';
export const SOLUTIONS_OVERLIMITED = 'SY.SOLUTIONS_OVERLIMITED';
export const SOLUTIONS_NO_CONTRACT = 'GQ.SYM_001:The store has no contract for Solutions.';
