import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';

import { LayoutPrimary } from './layout-primary';
import { LayoutSecondary } from './layout-secondary';
import { Login } from './login';
import { ResetPassword } from './reset-password';
import { Profile } from './profile';
import { Registration } from './registration';
import { LandingPage } from './landing-page';
import { CookieBanner } from './cookie-banner';
import { MyLenses } from './my-lenses';
import { MyBonuses } from './my-bonuses';
import { PointsHowTo } from './points-how-to';
import { CookiePolicy } from './cookie-policy';
import { PageNotFound } from './page-not-found';
import { Program } from './program';
import { EcpLocator } from './ecp-locator';
import { Appointment } from './appointment/appointment.container';
import { LOCALE } from '../../../constants';
import { InternalLinks } from '../../../constants/internal-links';
import { VisionProfilePage } from './vision-profile';
import { usePrivateRoute } from '../../../hooks/use-private-route';
import { AppLevelModals } from './app-level-modals';
import { MyOrdersPage } from './order/my-orders-page';
import { OrderCreatePage } from './order/order-create-page';

export const App = (): JSX.Element => {
  const intl = useIntl();
  const { getPrivateElement } = usePrivateRoute();
  const newRelicEnabled = process.env.REACT_APP_NEW_RELIC_ENABLED;
  const licenceKey = process.env.REACT_APP_NEW_RELIC_LICENSE_KEY as string;
  const applicationId = process.env.REACT_APP_NEW_RELIC_APPLICATION_ID as string;
  const accountId = process.env.REACT_APP_NEW_RELIC_ACCOUNT_ID as string;
  const agentId = process.env.REACT_APP_NEW_RELIC_AGENT_ID as string;
  const trustKey = process.env.REACT_APP_NEW_RELIC_TRUST_KEY as string;

  if (newRelicEnabled) {
    const s = document.createElement('script');
    s.id = 'new-relic-mya';
    s.src = 'assets/script/new-relic.js';
    s.setAttribute('data-new-relic-license-key', licenceKey);
    s.setAttribute('data-new-relic-application-id', applicationId);
    s.setAttribute('data-new-relic-account-id', accountId);
    s.setAttribute('data-new-relic-agent-id', agentId);
    s.setAttribute('data-new-relic-trust-key', trustKey);
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: LOCALE }}>
        <title>
          {intl.formatMessage({ id: 'app.title' })}
        </title>
        <meta name="description" content={intl.formatMessage({ id: 'app.description' })} />
        <script type="text/javascript" src="../../../assets/script/new-relic.js" />
      </Helmet>
      <Routes>
        <Route element={<LayoutPrimary />}>
          <Route path="/" element={<LandingPage />} />
          <Route path={InternalLinks.cookiePage} element={<CookiePolicy />} />
          <Route path={InternalLinks.programPage} element={<Program />} />
          <Route path={InternalLinks.ecpLocator} element={<EcpLocator />} />
          <Route path={InternalLinks.profilePage} element={getPrivateElement(<Profile />)} />
          <Route path={InternalLinks.myLensesPage} element={getPrivateElement(<MyLenses />)} />
          <Route path={InternalLinks.myBonusesPage} element={getPrivateElement(<MyBonuses />)} />
          <Route path={InternalLinks.pointsHowToPage} element={getPrivateElement(<PointsHowTo />)} />
          <Route path={InternalLinks.appointment} element={getPrivateElement(<Appointment />)} />
          <Route path={InternalLinks.visionProfile} element={getPrivateElement(<VisionProfilePage />)} />
          <Route path={InternalLinks.myOrdersPage} element={getPrivateElement(<MyOrdersPage />)} />
          <Route path={InternalLinks.orderCreate} element={getPrivateElement(<OrderCreatePage />)} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
        <Route element={<LayoutSecondary />}>
          <Route path={InternalLinks.login} element={<Login />} />
          <Route path={InternalLinks.registration} element={<Registration />} />
          <Route path={InternalLinks.resetPassword} element={<ResetPassword />} />
        </Route>
      </Routes>
      <CookieBanner />
      <AppLevelModals />
    </>
  );
};
