import {
  FittingTypes,
  IFittingsForSurvey,
} from '../../../../../../../graphql/app-level-modals/models/get-modals-data.model';

export const removeControlVisit = (fittingData: IFittingsForSurvey[]): IFittingsForSurvey[] => {
  let filteredData: IFittingsForSurvey[] = [];

  filteredData = fittingData.filter((
    fitting,
  ) => fitting.fitType !== FittingTypes.controlVisit);

  return filteredData;
};
