import React from 'react';
import { Rating as MuiRating } from '@mui/material';

import { IRatingProps } from './rating.models';
import { useStyles } from './rating.styles';
import { ReactComponent as RateChosenIcon } from '../../../assets/images/icons/rate-chosen.svg';
import { ReactComponent as RateNotChosenIcon } from '../../../assets/images/icons/rate.svg';

export const Rating = (props: IRatingProps): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiRating
      {...props}
      data-testid="rating"
      classes={{
        root: classes.root,
      }}
      icon={(
        <RateChosenIcon
          className={classes.icon}
          data-testid="rate-chosen"
        />)}
      emptyIcon={(
        <RateNotChosenIcon
          className={classes.icon}
          data-testid="rate-not-chosen"
        />
      )}
    />
  );
};
