import { setTokenToStorage } from '../../../utils/user';
import { ITokenData } from '../../../rest/oauth/oauth.models';

export const login = (tokenData: ITokenData) => {
  setTokenToStorage(tokenData);
  location.href = '/';
};

export const relogin = (tokenData: ITokenData) => {
  setTokenToStorage(tokenData);
};

export const useLogin = () => ({
  login,
  relogin,
});
