import { cx } from '@emotion/css';
import { useIntl } from 'react-intl';

import { ISupportBlockProps } from './support-block.models';
import { Button } from '../../../../../common/button';

import { useStyles } from './support-block.styles';

export const SupportBlock = (props: ISupportBlockProps): JSX.Element => {
  const {
    title,
    description,
    content,
    action,
    phoneNumber,
  } = props;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div
      className={classes.section}
      data-testid="footer-support-block"
    >
      <h2
        className={cx(classes.text, classes.title)}
        id="help"
        tabIndex={-1}
        data-testid="footer-support-title"
      >
        {title}
      </h2>
      {content}
      <p className={cx(classes.text, classes.description)}>
        {description}
      </p>
      <a
        href={intl.formatMessage({ id: 'common.phoneNumber.support.href' })}
        className={classes.button}
        data-testid="footer-phone-link"
      >
        {phoneNumber}
      </a>
      <Button
        {...action}
        className={classes.plainButton}
        variant="text"
        data-testid="footer-callback"
      />
    </div>
  );
};
