import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';

import { ContentWrapper } from '../content-wrapper';
import { Box, ResponsiveSize } from '../../../common/box';
import { LoginForm } from './login-form';
import { ILoginProps } from './login.models';
import { useGoBack } from '../../../../hooks/use-go-back';

export const Login = ({
  onLogin,
}: ILoginProps): JSX.Element => {
  const intl = useIntl();
  const { goBack } = useGoBack();

  const handleBack = useCallback(() => goBack(), [goBack]);

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage({ id: 'loginPage.title' })}
        </title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'loginPage.description' })}
        />
      </Helmet>
      <ContentWrapper withoutPaddings>
        <Box
          responsiveSize={ResponsiveSize.xs}
          center
          title={(
            <FormattedMessage
              id="login.title"
            />
          )}
          backButtonUrl={handleBack}
        >
          <LoginForm onSubmit={onLogin} />
        </Box>
      </ContentWrapper>
    </>
  );
};
