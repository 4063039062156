import React, { useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useMutation, useQuery } from '@apollo/client';

import { yup } from '../../../../services/yup';
import { useBind } from '../../../../hooks/use-bind';
import { useSnackbar } from '../../../../hooks/use-snackbar';
import { Profile as ProfileView } from './profile';
import { UPDATE_PROFILE } from '../../../../graphql/profile/mutations';
import { GET_PROFILE } from '../../../../graphql/profile/queries';
import { useActionsInProgress } from '../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../hooks/use-cancellable-promise';
import { useFirstNameValidation } from '../../../../hooks/use-first-name-validation';
import { useLastNameValidation } from '../../../../hooks/use-last-name-validation';
import { useEmailValidation } from '../../../../hooks/use-email-validation';
import { useEmailExitValidation } from '../../../../hooks/use-email-exit-validation';
import { oauthApi } from '../../../../rest/oauth';
import { getAuthorizationHeader } from '../../../../utils/request-headers/get-authorization-header';
import { getTokenFromStorage } from '../../../../utils/user';
import { RegistrationErrorCodes } from '../registration';
import { IProfileData } from '../../../../graphql/profile/models/get-profile.models';
import { ICompareFormValues } from './profile-form';
import { InternalLinks } from '../../../../constants';
import { useNavigate } from 'react-router-dom';

export const Profile = (): JSX.Element => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const profileData = useQuery(GET_PROFILE, { fetchPolicy: 'network-only' });
  const [updateProfile, updateProfileMutationSettings] = useMutation(UPDATE_PROFILE);
  const { loading } = updateProfileMutationSettings;
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();
  const { handleEmailExist } = useEmailExitValidation();

  const firstNameValidation = useFirstNameValidation();
  const lastNameValidation = useLastNameValidation();
  const emailValidation = useEmailValidation();

  const validationSchema = useMemo(
    () => yup
      .object({
        firstName: firstNameValidation,
        lastName: lastNameValidation,
        email: emailValidation,
      }),
    [firstNameValidation, lastNameValidation, emailValidation, intl],
  );

  const loadingBind = useBind(loading);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const updateProfileBind = useBind(updateProfile);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);
  const navigate = useNavigate();
  const handleProfileUpdate = useCallback(async (
    data: IProfileData, compareData: ICompareFormValues,
  ) => {
    if (loadingBind.current) {
      return;
    }

    addActionInProgressBind.current();

    try {
      const { email } = compareData;
      if (email !== data.email) {
        await makeCancellablePromiseBind.current(
          oauthApi.registerEmail({
            email: data.email,
          }, {
            headers: {
              Authorization: getAuthorizationHeader(getTokenFromStorage()?.access_token),
            },
          }),
        );
      }
      removeActionInProgressBind.current();
      addActionInProgressBind.current();

      try {
        await makeCancellablePromiseBind.current(
          updateProfileBind.current({ variables: data }),
        );
        removeActionInProgressBind.current();

        enqueueSnackbarBind.current(
          <FormattedMessage id="common.successUpdate" />,
          { variant: 'success' },
        );
        navigate(InternalLinks.myOrdersPage, { state: { hasPreviousLocation: true } });
      } catch (error) {
        removeActionInProgressBind.current();

        if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
          // eslint-disable-next-line no-useless-return
          return;
        }
      }
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        // eslint-disable-next-line no-useless-return
        return;
      }

      if (error.response) {
        const { status, data: { errors: { 0: { code } } } } = error.response;

        switch (status) {
          case 403:
            if (code === RegistrationErrorCodes.emailExist) {
              handleEmailExist();
            }
            break;
          default:
            break;
        }
      }
    }
  }, [
    loadingBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    updateProfileBind,
    enqueueSnackbarBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <ProfileView
      onProfileUpdate={handleProfileUpdate}
      profileData={profileData}
      validationSchema={validationSchema}
    />
  );
};
