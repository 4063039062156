import React, { useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import { useGoBack } from '../../../../../hooks/use-go-back';
import { yup } from '../../../../../services/yup';
import { Button } from '../../../../common/button';
import { TextField } from '../../../../common/form/text-field';
import { Form } from '../../../../common/form';
import {
  usePhoneNumberValidation,
} from '../../../../../hooks/use-phone-number-validation';
import { usePasswordValidation } from '../../../../../hooks/use-password-validation';
import { ILoginFormProps } from './login-form.models';
import { useStyles } from './login-form.styles';
import { InternalLinks } from '../../../../../constants';

export const LoginForm = ({
  onSubmit,
}: ILoginFormProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { hasPreviousLocationState } = useGoBack();
  const defaultValues = useMemo(() => ({
    username: '',
    password: '',
  }), []);

  const phoneNumberValidation = usePhoneNumberValidation();
  const passwordValidation = usePasswordValidation();

  const validationSchema = useMemo(() => yup.object({
    username: phoneNumberValidation,
    password: passwordValidation,
  }), [passwordValidation, phoneNumberValidation]);

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
    >
      <TextField
        autoFocus
        name="username"
        type="tel"
        fullWidth
        label={(
          <FormattedMessage
            id="login.phoneTextField.label"
          />
        )}
      />
      <TextField
        name="password"
        type="password"
        fullWidth
        label={(
          <FormattedMessage
            id="login.passwordTextField.label"
          />
        )}
        placeholder={intl.formatMessage({ id: 'login.passwordTextField.placeholder' })}
        footerActionProps={{
          className: classes.resetLink,
          'data-testid': 'password-forget-link',
          to: InternalLinks.resetPassword,
          state: hasPreviousLocationState,
          children: (
            <FormattedMessage
              id="login.resetPasswordLink.label"
            />
          ),
        }}
      />
      <div className={classes.actions}>
        <Button
          type="submit"
          variant="contained"
          data-testid="login-submit"
          fullWidth
        >
          <FormattedMessage
            id="login.loginButton.label"
          />
        </Button>
        <Button
          data-testid="register-link"
          variant="outlined"
          fullWidth
          to={InternalLinks.registration}
          state={hasPreviousLocationState}
        >
          <FormattedMessage
            id="login.registrationButton.label"
          />
        </Button>
      </div>
    </Form>
  );
};
