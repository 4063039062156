import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cx } from '@emotion/css';
import { MarkerCluster } from '../../../../common/marker-cluster';

import { IEcpMapProps } from './ecp-map.models';
import {
  GOOGLE_MAP_OPTIONS,
  CHILD_ZOOM,
  LIST_ZOOM,
  MAP_OPTIONS,
} from '../../../../../constants/ecp-locator';
import { useStyles } from './ecp-map.styles';
import { IEcpStore } from '../../../../../rest/ecp-locator';
import { getCampaignMarkerIcon, getLatLng, getMarkerIcon } from '../utils';
import {
  setUserLocation,
  setStoreDetail,
  setMarkerCode,
} from '../../../../../graphql/ecp-locator/ecp-locator.cache';
import { scrollToTop } from '../../../../../utils/scroll-to-top';
import { setIsLocatorIconsCampaign } from '../../../../../graphql/configuration/configuration.cache';

export const EcpMap = ({
  ecpStores,
  storeDetail,
  isMapHidden,
  ...props
}: IEcpMapProps): JSX.Element => {
  const classes = useStyles();
  const userLocation = useReactiveVar(setUserLocation);
  const markerCode = useReactiveVar(setMarkerCode);
  const [initialMap, setInitialMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState(LIST_ZOOM);
  const [center, setCenter] = useState(getLatLng(userLocation));
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_OPTIONS.googleMapsApiKey,
    libraries: GOOGLE_MAP_OPTIONS.libraries,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const [scrollY, setScrollY] = useState('');

  const handleWindowScroll = () => {
    if (window.scrollY > 120) {
      setScrollY('scrolled');
    } else {
      setScrollY('not-scrolled');
    }
  };

  useEffect(() => {
    if (isDesktop) {
      window.removeEventListener('scroll', handleWindowScroll);
      window.addEventListener('scroll', handleWindowScroll);
    }

    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, [isDesktop]);

  useEffect(() => {
    if (window.google) {
      // Center marker after click on reduced store card.
      if (storeDetail && storeDetail.latitude && storeDetail.longitude) {
        setZoom(CHILD_ZOOM);
        setCenter(getLatLng(storeDetail));
        // To change marker style to active.
        if (storeDetail.code !== markerCode) {
          setMarkerCode(storeDetail.code);
        }
      }
      // Set default center after detailed card is closed.
      if (!storeDetail) {
        setZoom(LIST_ZOOM);
        setCenter(getLatLng(userLocation));
      }
    }
  }, [storeDetail]);

  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  const onMarkerLoad = (marker: google.maps.Marker, store: IEcpStore) => {
    marker.setMap(initialMap);

    if (store.code && markerCode === store.code) {
      marker.setIcon(isCampaignIcon
        ? getCampaignMarkerIcon(store!, markerCode!)
        : getMarkerIcon(store!, markerCode!));
    }
  };

  const onMarkerClick = (event: google.maps.MapMouseEvent, store: IEcpStore): void => {
    // To change marker style to active.
    if (markerCode !== store.code) {
      setMarkerCode(store.code);
    }
    // To open store detail on marker click.
    setStoreDetail(store);
    setCenter(getLatLng(store));
  };

  useEffect(() => {
    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      ecpStores.forEach((ecpStore) => bounds.extend(getLatLng(ecpStore)));
      setZoom(LIST_ZOOM);
      setCenter(getLatLng(userLocation));
      if (initialMap) {
        initialMap.panToBounds(bounds);
      }
    }
  }, [ecpStores]);

  useEffect(() => {
    if (isMobile && !isMapHidden) {
      scrollToTop();
    }
  }, [isMapHidden, isMobile]);

  useEffect(() => {
    if (window.google && initialMap) {
      const mapCenter = new window.google.maps.LatLng({
        lat: Number(userLocation.latitude),
        lng: Number(userLocation.longitude),
      });
      initialMap.panTo(mapCenter);
    }
  }, [initialMap, userLocation]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setInitialMap(map);
  }, [setInitialMap, setCenter]);

  const onUnmount = useCallback(() => {
    setInitialMap(null);
  }, [setInitialMap]);

  return (
    <div
      className={cx(classes.mapWrapper, {
        [classes.mapWrapperScrolled]: scrollY === 'scrolled',
      })}
      data-testid="google-map-wrapper"
    >
      {
        isLoaded && (
          <GoogleMap
            {...props}
            mapContainerClassName={classes.mapContainerStyle}
            center={center}
            options={MAP_OPTIONS}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {ecpStores.length && (
              <MarkerCluster
                ecpStores={ecpStores}
                onMarkerClick={onMarkerClick}
                onMarkerLoad={onMarkerLoad}
                markerCode={markerCode}
              />
            )}
          </GoogleMap>
        )
      }
    </div>
  );
};
