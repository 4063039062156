export const replaceChar = (
  str: string,
  index: number = -2,
  char: string = ',',
) => {
  let formattedStr;
  if (index > str.length - 1 || !str.length) {
    formattedStr = str;
  } else {
    formattedStr = `${str.slice(0, index)}${char}${str.slice(index + 1)}`;
  }
  return formattedStr;
};

export const roundRating = (
  num: number,
): string => replaceChar(num.toFixed(1));
