import { useCallback, useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';

import { useEcpStoresFromCache } from '../graphql/ecp-locator/actions/ecp-stores';
import { filterParams, setUserLocation } from '../graphql/ecp-locator/ecp-locator.cache';
import {
  ServiceTypeFilters,
  TFiltersData,
} from '../graphql/ecp-locator/models/filter-params.models';
import { ecpLocatorApi } from '../rest/ecp-locator';
import { useSetFilterParams } from '../graphql/ecp-locator/actions/set-filter-params';
import { TEcpMapUserLocation } from '../components/business/app/ecp-locator/ecp-map';
import { useEcpUserLocation } from '../graphql/ecp-locator/actions/user-location';
// import { DEFAULT_RANGE } from '../constants/ecp-locator';
import { filteringByStores } from '../components/business/app/ecp-locator/utils';
import { EcpStoreType } from '../rest/ecp-locator';

export interface IUpdateStores {
  filters?: TFiltersData;
  userLocation?: TEcpMapUserLocation;
}

export const useUpdateEcpStores = (isFitted?: boolean) => {
  const { setEcpStoresToCache } = useEcpStoresFromCache();
  const { setFilterParamsToCache } = useSetFilterParams();
  const { setEcpUserLocationToCache } = useEcpUserLocation();
  const filtersCurrent = useReactiveVar(filterParams);
  const userLocationCurrent = useReactiveVar(setUserLocation);
  const [isStoresLoading, setLoadingState] = useState(false);
  const [isStoresError, setErrorState] = useState(false);

  const updateStores = useCallback(async ({ filters, userLocation }: IUpdateStores) => {
    let filtersApplied = filtersCurrent;
    let userLocationApplied = userLocationCurrent;

    if (filters) {
      filtersApplied = filters;
    }

    if (userLocation) {
      userLocationApplied = userLocation;
    }

    const { specializations, services, shops } = filtersApplied;
    const { latitude, longitude } = userLocationApplied;
    const isC2cStores = services === EcpStoreType.c2cActivated;
    const isC2cStoresServices: ServiceTypeFilters = isFitted
      ? EcpStoreType.retailer
      : EcpStoreType.fitter;

    try {
      setLoadingState(true);
      const { data } = await ecpLocatorApi.getStores({
        lat: latitude,
        long: longitude,
        range: 100,
        sorting: 'rating',
        speciality: specializations.join(','),
        type: isC2cStores ? isC2cStoresServices : services || '',
        c2cActivated: isC2cStores,
      });

      if (filters) {
        setFilterParamsToCache(filters);
      }

      if (userLocation) {
        setEcpUserLocationToCache(userLocation);
      }

      setEcpStoresToCache(filteringByStores(data, shops));
    } catch {
      setErrorState(true);
    }
    setLoadingState(false);
  }, [
    filtersCurrent,
    userLocationCurrent,
    setEcpStoresToCache,
    setEcpUserLocationToCache,
    setFilterParamsToCache,
  ]);

  useEffect(() => () => {
    setLoadingState(false);
  }, []);

  return { updateStores, isStoresLoading, isStoresError };
};
