import { useStyles } from './campaign-icon.styles';

export const CampaignIcon = (): JSX.Element => {
  const classes = useStyles();

  return (
    <span
      data-testid="campaign-wrapper-footer"
      aria-hidden="true"
      className={classes.campaign}
    />
  );
};
