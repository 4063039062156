import {
  useState,
  useMemo,
  useRef,
  useEffect,
  ChangeEvent,
} from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from '@apollo/client';

import { yup } from '../../../../../../../services/yup';
import { FeedbackType, IFormValues, IRating, ISurveyModalProps } from './survey-modal.models';
import { SurveyModal as SurveyModalView } from './survey-modal';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { NotificationTargetId } from '../../../../../../../models/notification.models';
import { CREATE_SURVEY_RESPONSE_FOR_FITTINGS } from '../../../../../../../graphql/surveys/mutations/create-survey-response-for-fittings';
import { useActionsInProgress } from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { useCancellablePromise } from '../../../../../../../hooks/use-cancellable-promise';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { getFeedbacktype } from '../utils/get-feedback-type';

export const SurveyModal = ({
  fittingProps, onClose, ...props
}: ISurveyModalProps): JSX.Element => {
  const intl = useIntl();
  const { makeCancellablePromise, CancelledPromiseOnUnmountError } = useCancellablePromise();
  const [createSurveyResponse] = useMutation(CREATE_SURVEY_RESPONSE_FOR_FITTINGS);
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const [rating, setRating] = useState<IRating>(null);
  const [previousFeedbackType, setPreviousFeedbackType] = useState<FeedbackType | null>(null);
  const feedbackFormRef = useRef<IFormImperativeHandleProps>(null);
  const { sourceObjectId, consumerId, storeName, updateFittings } = fittingProps;

  const defaultFormValues = useMemo(() => ({
    feedback: [],
    comment: '',
  }), []);

  const validationSchema = useMemo(() => yup.object({
    comment: yup.string().max(255, intl.formatMessage({ id: 'common.errors.max' })),
  }), []);

  const handleRatingChange = (event: ChangeEvent<{}>, value: IRating) => {
    if (value) {
      setRating(value);
    }
  };

  const handleSubmitButtonClick = () => {
    if (feedbackFormRef.current) {
      feedbackFormRef.current.submit();
    }
  };

  const handleSurveyResponse = async ({ comment, feedback }: IFormValues) => {
    addActionInProgress();

    try {
      await makeCancellablePromise(
        createSurveyResponse({
          variables: {
            surveyResponse: {
              consumerId,
              sourceObjectId,
              response: `a${rating}`,
              comment: `${comment};SYSTEM-GENERATED=${JSON.stringify(feedback)}`,
              surveyId : +NotificationTargetId.AFTERCARE,
              type: 'SurveyResponse',
            },
          },
        }),
      );
  
      updateFittings(sourceObjectId);

      onClose!();
    } catch (error) {
      if (error instanceof CancelledPromiseOnUnmountError) {
        return;
      }

      if (promiseErrorCallbacks.anyError) {
        promiseErrorCallbacks.anyError();
      }
    }

    removeActionInProgress();
  };

  useEffect(() => {
    if (rating !== null) {
      const feedbackType = getFeedbacktype(rating);

      if (feedbackFormRef.current && previousFeedbackType !== feedbackType) {
        const { feedback } = defaultFormValues;

        feedbackFormRef.current.reset({ feedback });
        setPreviousFeedbackType(feedbackType);
      }
    }

  }, [rating]);

  return (
    <SurveyModalView
      {...props}
      ref={feedbackFormRef}
      rating={rating}
      storeName={storeName}
      defaultFormValues={defaultFormValues}
      validationSchema={validationSchema}
      onSurveyResponse={handleSurveyResponse}
      onRatingChange={handleRatingChange}
      onSubmitButtonClick={handleSubmitButtonClick}
    />
  );
};
