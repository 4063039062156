import React, { useCallback } from 'react';
import { useModal } from 'mui-modal-provider';
import { FormattedMessage } from 'react-intl';

import { useLogout } from '../../../../../graphql/user/actions/logout';
import { useBind } from '../../../../../hooks/use-bind';
import { useCancellablePromise } from '../../../../../hooks/use-cancellable-promise';
import { consumerApi } from '../../../../../rest/consumer';
import { Button } from '../../../../common/button';
import { IDeleteProfileAction } from './delete-profile-action.models';
import { DeleteProfileModal } from './delete-profile-modal';
import { useActionsInProgress } from '../../../../../graphql/preloader/actions/actions-in-progress';

export const DeleteProfileAction = ({ phoneNumber }: IDeleteProfileAction): JSX.Element => {
  const { showModal } = useModal();
  const { logout } = useLogout();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();

  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();

  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const logoutBind = useBind(logout);
  const phoneNumberBind = useBind(phoneNumber);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleDeleteConfirmation = useCallback(() => {
    const { hide, update } = showModal(DeleteProfileModal, {
      step: 'stepTwo',
      onConfirm: async () => {
        update({
          isInProgress: true,
        });

        addActionInProgressBind.current();

        try {
          await makeCancellablePromiseBind.current(consumerApi.delete({
            phoneNumber: phoneNumberBind.current,
          }));

          removeActionInProgressBind.current();
          update({
            isInProgress: false,
          });
          hide();

          logoutBind.current();
        } catch (error) {
          removeActionInProgressBind.current();

          if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
            // eslint-disable-next-line no-useless-return
            return;
          }

          update({
            isInProgress: false,
          });
        }
      },
    });
  }, [
    showModal,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    logoutBind,
    phoneNumberBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  const handleDelete = useCallback(() => {
    const { hide } = showModal(DeleteProfileModal, {
      step: 'stepOne',
      onConfirm: () => {
        handleDeleteConfirmation();
        hide();
      },
    });
  }, [handleDeleteConfirmation, showModal]);

  return (
    <Button
      fullWidth
      onClick={handleDelete}
    >
      <FormattedMessage id="profile.deleteProfileButton.label" />
    </Button>
  );
};
