import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ spacing, breakpoints }: Theme) => ({
  socialNetworksList: {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: spacing(2),
    justifyContent: 'center',
    padding: '0',
    marginTop: 0,
    marginBottom: spacing(2),
    listStyleType: 'none',
    [breakpoints.up('md')]: {
      marginBottom: spacing(1.5),
    },
    [breakpoints.up('lg')]: {
      marginBottom: spacing(2.5),
    },
  },
}));
