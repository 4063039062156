import { gql } from '@apollo/client';

export const GET_POINTS_BONUSES = gql`
  query getPointsBonuses {
    voucherByAuthId {
      packSize
      value
      type
      brands {
        product
        productType
        fullName
        labels {
          name
          labelEndDate
          labelStartDate
        }
      }
    }
    getDynamicComponents {
      id
      type
      data {
        title
        shortDescription
        endDate
        externalLink
      }
    }
    pointByAuthId {
      id
      eligibleBalance
    }
    consumerLensesByAuthID {
      id
      createdManual
    }
    purchasesByAuthId {
      id
    }
  }
`;
