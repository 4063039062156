import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from 'mui-modal-provider';
import GTM from 'react-gtm-module';

import { Login as LoginView } from './login';
import { oauthApi, ILoginData } from '../../../../rest/oauth';
import { useSnackbar } from '../../../../hooks/use-snackbar';
import { useBind } from '../../../../hooks/use-bind';
import { useCancellablePromise } from '../../../../hooks/use-cancellable-promise';
import { useLogin } from '../../../../graphql/user/actions/login';
import {
  useActionsInProgress,
} from '../../../../graphql/preloader/actions/actions-in-progress';
import { LoginConsumerBanModal } from './login-consumer-ban-modal';
import { ACCOUNT_BLOCKED_MESSAGE } from '../../../../constants/errors';

export const Login = (): JSX.Element => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    makeCancellablePromise,
    CancelledPromiseOnUnmountError,
  } = useCancellablePromise();
  const { login } = useLogin();
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const { showModal } = useModal();

  const setIsLoggingInBind = useBind(setIsLoggingIn);
  const isLoggingInBind = useBind(isLoggingIn);
  const makeCancellablePromiseBind = useBind(makeCancellablePromise);
  const CancelledPromiseOnUnmountErrorBind = useBind(CancelledPromiseOnUnmountError);
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const loginBind = useBind(login);
  const addActionInProgressBind = useBind(addActionInProgress);
  const removeActionInProgressBind = useBind(removeActionInProgress);

  const handleConsumerBan = (expirationDate: string) => {
    if (expirationDate) {
      showModal(LoginConsumerBanModal, { expirationDate: expirationDate });
    } else {
      showModal(LoginConsumerBanModal, {});
    }
  };

  const handleLogin = useCallback(async (values: ILoginData) => {
    if (isLoggingInBind.current) {
      return;
    }

    setIsLoggingInBind.current(true);
    addActionInProgressBind.current();

    try {
      const {
        data,
      } = await makeCancellablePromiseBind.current(oauthApi.login(values));
      try {
        GTM.dataLayer({
          dataLayer: {
            event: 'gtm.login',
          },
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Something wrong with GTM: ${e}`);
      }

      removeActionInProgressBind.current();
      loginBind.current(data);
    } catch (error: any) {
      removeActionInProgressBind.current();

      if (error instanceof CancelledPromiseOnUnmountErrorBind.current) {
        return;
      }

      if (error.response) {
        const { status, data: { message, meta: { expiration } } } = error.response;

        switch (status) {
          case 401:
            enqueueSnackbarBind.current(
              <FormattedMessage id="login.errors.unauthorized" />,
              { variant: 'error' },
            );
            break;
          case 403:
            if (message === `${ACCOUNT_BLOCKED_MESSAGE}.`) {
              handleConsumerBan(expiration);
            }
            break;
          default:
            break;
        }
      }

      setIsLoggingInBind.current(false);
    }
  }, [
    setIsLoggingInBind,
    isLoggingInBind,
    makeCancellablePromiseBind,
    CancelledPromiseOnUnmountErrorBind,
    enqueueSnackbarBind,
    loginBind,
    addActionInProgressBind,
    removeActionInProgressBind,
  ]);

  return (
    <LoginView onLogin={handleLogin} />
  );
};
