import { ReactNode } from 'react';

import { TLinkHref } from '../../../models/link.models';

export interface IBoxProps {
  title?: ReactNode;
  description?: ReactNode;
  footerDescription?: ReactNode;
  children?: ReactNode;
  responsiveSize?: ResponsiveSize;
  center?: boolean;
  responsiveMargins?: ResponsiveMargins;
  backButtonUrl?: TLinkHref;
  paperStyles?: string;
}

export enum ResponsiveSize {
  xs = 'xs',
  full = 'full',
}

export enum ResponsiveMargins {
  sm = 'sm',
  xs = 'xs',
}

export interface IRootConditionalClasses {
  [index: string]: string;
}
