import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../common/form';
import { ToggleButtonGroup } from '../../../../../common/form/toggle-button-group';
import { useStyles } from './ecp-filters-modal-form.styles';
import { IEcpFiltersModalFormProps } from './ecp-filters-modal-form.models';
import {
  serviceTypeFilteringItemsAohm,
  serviceTypeFilteringItems,
  shopTypeFilteringItems,
  specializationTypeFilteringItems,
} from '../filtering-criteria-items/filtering-criteria-items';
import { C2CMode } from '../../../../../common/configuration-settings/configuration-settings.models';

export const EcpFiltersModalForm = forwardRef<
IFormImperativeHandleProps, IEcpFiltersModalFormProps>(({
  defaultValues, onApplyFilters, isSingleAohm, c2CMode,
}, ref): JSX.Element => {
  const classes = useStyles();

  return (
    <Form
      ref={ref}
      defaultValues={defaultValues}
      onSubmit={onApplyFilters}
    >
      <h2 className={classes.filtersTypeTitle}>
        <FormattedMessage
          id="ecp.filters.modal.serviceType.title"
        />
      </h2>
      <ToggleButtonGroup
        name="services"
        exclusive
        standalone
        size="small"
        variant="outlined"
        // Hide C2C feature from aohm-only fitted users
        options={(!isSingleAohm && c2CMode !== C2CMode.disabled)
          ? serviceTypeFilteringItemsAohm
          : serviceTypeFilteringItems}
        buttonStyles={classes.c2cButtonStyles}
        groupStyles={isSingleAohm ? classes.c2cGroupStylesAohm : classes.c2cGroupStyles}
      />
      <h2 className={classes.filtersTypeTitle}>
        <FormattedMessage
          id="ecp.filters.modal.specializationType.title"
        />
      </h2>
      <ToggleButtonGroup
        name="specializations"
        standalone
        size="small"
        variant="outlined"
        options={specializationTypeFilteringItems}
      />
      <h2 className={classes.filtersTypeTitle}>
        <FormattedMessage
          id="ecp.filters.modal.shopType.title"
        />
      </h2>
      <ToggleButtonGroup
        name="shops"
        standalone
        size="small"
        variant="outlined"
        options={shopTypeFilteringItems}
      />
    </Form>
  );
});
