import { FormattedMessage } from 'react-intl';
import { ToggleButtonProps } from '@mui/material';

import { ToggleButtonGroup } from '../../../../../../../common/form/toggle-button-group';
import { FeedbackType } from '../../survey-modal';
import {
  IFeedbackToggleGroupProps,
  PositiveFeedbackOptions,
  NegativeFeedbackOptions,
} from './feedback-toggle-group.models';

const positiveFeedbackItems: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="survey.modal.positiveFeedback.buttons.lensesUsageEducation.label"
    />
  ),
  value: PositiveFeedbackOptions.lensesUsageEducation,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.positiveFeedback.buttons.staffProfessionalism.label"
    />
  ),
  value: PositiveFeedbackOptions.staffProfessionalism,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.positiveFeedback.buttons.qualityVisionLenses.label"
    />
  ),
  value: PositiveFeedbackOptions.qualityVisionLenses,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.positiveFeedback.buttons.convenienceMakingAppointment.label"
    />
  ),
  value: PositiveFeedbackOptions.convenienceMakingAppointment,
}];

const negativeFeedbackItems: ToggleButtonProps[] = [{
  children: (
    <FormattedMessage
      id="survey.modal.negativeFeedback.buttons.staffIncompetent.label"
    />
  ),
  value: NegativeFeedbackOptions.staffIncompetent,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.negativeFeedback.buttons.lackOfLensesUsageEducation.label"
    />
  ),
  value: NegativeFeedbackOptions.lackOfLensesUsageEducation,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.negativeFeedback.buttons.lowQualityVisionLenses.label"
    />
  ),
  value: NegativeFeedbackOptions.lowQualityVisionLenses,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.negativeFeedback.buttons.longWaitingTimeAppointment.label"
    />
  ),
  value: NegativeFeedbackOptions.longWaitingTimeAppointment,
}, {
  children: (
    <FormattedMessage
      id="survey.modal.negativeFeedback.buttons.complicatedMakingAppointment.label"
    />
  ),
  value: NegativeFeedbackOptions.complicatedMakingAppointment,
}];

export const FeedbackToggleGroup = ({ feedbackType }: IFeedbackToggleGroupProps): JSX.Element => (
  <ToggleButtonGroup
    name="feedback"
    options={feedbackType === FeedbackType.positive ? positiveFeedbackItems : negativeFeedbackItems}
    fullWidth
    standalone
    orientation="vertical"
    variant="outlined-light"
    data-testid={`${feedbackType}-feedback-items`}
  />
);
