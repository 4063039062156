import { makeVar } from '@apollo/client';
import { TEcpMapUserLocation } from '../../components/business/app/ecp-locator/ecp-map/ecp-map.models';
import { IEcpStore, TEcpLocatorResponse } from '../../rest/ecp-locator';
import { TFiltersData } from './models/filter-params.models';
import { DEFAULT_CENTER, DEFAULT_FILTERS } from '../../constants/ecp-locator';
import { SortingTypes } from '../../components/business/app/ecp-locator/ecp-sort/ecp-sort.models';

export const setInternetShops = makeVar<boolean>(false);
export const setStoreDetail = makeVar<IEcpStore | null>(null);
export const ecpStoresCached = makeVar<TEcpLocatorResponse>([]);
export const setMarkerCode = makeVar<string>('');
export const sortingType = makeVar<SortingTypes>(SortingTypes.rating);
export const setStoreCode = makeVar<string>('');
export const setUserLocation = makeVar<TEcpMapUserLocation>(DEFAULT_CENTER);
export const setAutocompletedSearch = makeVar<boolean>(false);
export const setAutocompletedValue = makeVar<string>('');
export const setAutoValueSelected = makeVar<boolean>(false);
export const setEcpFormSubmitted = makeVar<boolean>(false);
export const filterParams = makeVar<TFiltersData>({
  ...DEFAULT_FILTERS,
  isFirstTimeLoading: true,
});
export const setError = makeVar<string>('');
