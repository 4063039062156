import { differenceInCalendarDays } from 'date-fns';
import { cx } from '@emotion/css';

import { convertUIStringToDate } from '../../../utils/date/convert-string-to-date';
import { IDateExpiredLabelProps } from './date-expired-label.models';
import { useStyles } from './date-expired-label.styles';
import { FormattedMessage } from 'react-intl';
import { Icon, IconSizes, IconTypes } from '../icon';

const LABEL_ALERT_TIME = 7;

export const DateExpiredLabel = ({ endDate }: IDateExpiredLabelProps): JSX.Element => {
  const classes = useStyles();
  const daysLeft = differenceInCalendarDays(convertUIStringToDate(endDate)!, new Date());
  const isAlertTime = daysLeft <= LABEL_ALERT_TIME;
  const isDaysLeftPredefined = isNaN(daysLeft);

  const renderLabelMessage = () => {
    switch (true) {
      case daysLeft === 0:
        return (
          <span data-testid="endDate-today-message">
            <FormattedMessage
              id="common.endDate.today.label"
            />
          </span>
        );
      case daysLeft > LABEL_ALERT_TIME:
        return (
          <span data-testid="endDate-moreThanWeek-message">
            <FormattedMessage
              id="common.endDate.moreThanWeek.label"
              values={{ endDate }}
            />
          </span>
        );
      default:
        return (
          <span data-testid="endDate-lessThanWeek-message">
            <FormattedMessage
              id="common.endDate.lessThanWeek.label"
              values={{ daysLeft }}
            />
          </span>
        );
    }
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.alert]: isAlertTime,
      })}
      data-testid="date-expired-label"
    >
      <Icon
        className={cx(classes.icon, {
          [classes.iconAlert]: isAlertTime,
        })}
        type={IconTypes.clockBlack}
        size={IconSizes.xs}
      />
      {isDaysLeftPredefined ? endDate : renderLabelMessage()}
    </div>
  );
};
