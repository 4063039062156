import { FormattedMessage } from 'react-intl';

import { BonusCardProps } from './bonus-card.models';
import { BonusCard as BonusCardBase, Icon, IconSizes } from '../../../../../../../../common';
import { getPromoIconType } from '../../../../../../../../../utils/promotions/get-promo-icon-type';
import { useStyles } from './bonus-card.styles';
import {
  PromotionType,
} from '../../../../../../../../../graphql/promotions/models/get-promotions.models';
import { BonusType } from '../../../../../../../../../graphql/bonuses/models/get-bonuses.models';

export const BonusCard = ({
  type, value, id, title, description, onBonusSelect, name,
}: BonusCardProps): JSX.Element => {
  const classes = useStyles();
  const bonusDescription = type === BonusType.discount
    ? (
      <FormattedMessage
        id="userActivities.discounts.discountCard.description"
      />
    ) : description;

  const handleCardSelect = () => {
    onBonusSelect(id, {
      title,
      description,
      type,
      value,
      name,
    });
  };

  return (
    <BonusCardBase
      data-testid="bonus-card"
      isAvailable
      cardTitle={title}
      description={bonusDescription}
      bonusImage={(
        <Icon
          type={getPromoIconType((type).toUpperCase() as PromotionType, true)}
          size={IconSizes['3xl']}
        />
      )}
      onClick={handleCardSelect}
      titleStyles={classes.title}
      descriptionStyles={classes.description}
      rootStyles={classes.root}
    />
  );
};
