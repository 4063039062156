export interface IPromotions {
  getDynamicComponents: IPromotion[];
}

export interface IPromotion {
  type: PromotionType;
  data: IPromotionData;
  id: string;
}

export interface IPromotionData {
  title: string;
  shortDescription: string;
  endDate: string;
  externalLink: string;
  startDate: string;
}

export enum PromotionType {
  freepack = 'FREEPACK',
  discount = 'DISCOUNT',
  giftCertificate = 'GIFT_CERTIFICATE',
  discountFirstPurchase = 'DISCOUNT_FIRST_PURCHASE',
  discountSecondPurchase = 'DISCOUNT_SECOND_PURCHASE',
}
