import React from 'react';
import { Paper as MuiPaper } from '@mui/material';
import { capitalize } from 'lodash';
import { cx } from '@emotion/css';

import { Link } from 'react-router-dom';
import { useStyles } from './card.styles';
import { TCardProps, CardTypes, IAdditionalProps } from './card.models';

export const Card = ({
  children, to, href, type = CardTypes.default, state, ...props
}: TCardProps): JSX.Element => {
  const classes = useStyles(props);
  const additionalProps: IAdditionalProps = {};
  const cardRef = React.useRef<HTMLDivElement>(null);

  if (href) {
    additionalProps.component = 'a';
    additionalProps.href = href;
    additionalProps.target = '_blank';
  }

  if (to) {
    additionalProps.component = Link;
    additionalProps.to = to;
    additionalProps.state = state;
  }

  return (
    <MuiPaper
      {...props}
      {...additionalProps}
      ref={cardRef}
      classes={{
        root: cx(
          classes.cardRoot,
          classes[
            `cardRootType${capitalize(type)}` as 'cardRootTypeDefault'
            | 'cardRootTypePrimary'
            | 'cardRootTypeSecondary'
            | 'cardRootTypeTertiary'
            | 'cardRootTypeQuaternary'
            | 'cardRootTypeBonus'
          ],
        ),
      }}
      elevation={0}
    >
      {children}
    </MuiPaper>
  );
};