import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { IMyBonusesCard } from './my-bonuses-card.models';
import { Card } from '../../../../../../common/card';
import { Button } from '../../../../../../common/button';
import { useStyles } from './my-bonuses-card.styles';
import { InternalLinks } from '../../../../../../../constants/internal-links';
import { Divider } from '../../../../../../common/divider';
import { BonusesList, IBonuses } from './bonuses-list';
import { IconTypes } from '../../../../../../common';
import { Promotions } from './promotions';

export const MyBonusesCard = ({ points }: IMyBonusesCard): JSX.Element => {
  const classes = useStyles();
  const { pointByAuthId: { eligibleBalance }, getDynamicComponents } = points;
  const promotionsAmount = getDynamicComponents.length;

  const bonuses = useMemo(() => {
    const result: IBonuses[] = [{
      icon: IconTypes.aRoundedBlue,
      description: (
        <FormattedMessage
          id="myPointsPreview.bonuses.points"
          values={{ amount: eligibleBalance }}
        />
      ),
    }, {
      icon: IconTypes.voucher,
      description: <Promotions promotions={getDynamicComponents} />,
    }];

    return result;
  }, [eligibleBalance, promotionsAmount]);

  return (
    <Card
      to={InternalLinks.myBonusesPage}
      className={classes.card}
      data-testid="my-bonuses-preview"
    >
      <div className={classes.cardContainer}>
        <div>
          <h3 className={classes.title}>
            <FormattedMessage
              id="myPointsPreview.title"
            />
          </h3>
          <BonusesList bonuses={bonuses} />
        </div>
        <div
          className={classes.pointsQuantity}
          data-testid="my-bonuses-preview-balance"
        >
          {eligibleBalance}
        </div>
      </div>
      <Divider />
      <div className={classes.pointsAndSalesLink}>
        <Button
          variant="text"
        >
          <FormattedMessage
            id="myPointsPreview.showPointsAndSales.label"
          />
        </Button>
      </div>
    </Card>
  );
};
