import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useModal } from 'mui-modal-provider';

import {
  IAppLevelModalsData,
  IFittingsForSurvey,
} from '../../../../../../graphql/app-level-modals/models/get-modals-data.model';
import { GET_APP_LEVEL_MODALS_DATA } from '../../../../../../graphql/app-level-modals/queries/get-modals-data';
import { SurveyModal } from './survey-modal';
import { getActiveSurveys } from './utils/get-active-surveys';
import { removeControlVisit } from './utils/remove-control-visit';

export const useFittingsSurveyModal = () => {
  const [shouldModalUpdate, setModalUpdateState] = useState(true);
  const { updateQuery } = useQuery<IAppLevelModalsData>(GET_APP_LEVEL_MODALS_DATA);
  const { showModal } = useModal();

  const updateFittings = useCallback((fittingId: string) => {
    setModalUpdateState(true);

    updateQuery((prevState) => {
      return {
        ...prevState,
        fittingByAuthId: prevState.fittingByAuthId
          .map((fitting) => fitting.id === fittingId
            ? { ...fitting, isRated: true }
            : fitting,
          ),
      };
    });
  }, [
    updateQuery,
    setModalUpdateState,
  ]);

  const handleFittingsSurveyModal = useCallback((surveyData: IFittingsForSurvey[]) => {
    if (shouldModalUpdate) {
      const noControlVisitSurveys = removeControlVisit(surveyData);
      const activeSurveys = getActiveSurveys(noControlVisitSurveys);

      if (activeSurveys.length) {
        const { id, consumerId, store: { displayName } } = activeSurveys[0];

        showModal(SurveyModal, {
          fittingProps: {
            consumerId,
            storeName: displayName,
            sourceObjectId: id,
            updateFittings,
          },
        });

        setModalUpdateState(false);
      }
    }
  }, [
    shouldModalUpdate,
    showModal,
    setModalUpdateState,
  ]);

  return { handleFittingsSurveyModal };
};
