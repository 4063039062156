import { useMemo } from 'react';

import { CmsBanner, CmsBannerType } from '../../../../common/cms-banner';
import { ContentWrapper } from '../../content-wrapper';
import { useStyles } from './primary-banner.styles';
import { IPrimaryBanner } from './primary-banner.models';
import { getBannerType } from './utils/get-banner-type';
import { useGetBanners } from '../../../../../hooks/use-get-banners';

export const PrimaryBanner = ({ bannerData }: IPrimaryBanner): JSX.Element | null => {
  const classes = useStyles();

  const bannerId = useMemo(() => {
    let id: CmsBannerType | string = '';

    if (bannerData?.getDynamicComponents) {
      id = getBannerType(bannerData.getDynamicComponents);
    }

    return id;
  }, [bannerData]);

  const { isBanner, url, pwaCallToActionLink } = useGetBanners(bannerId);

  return (
    <>
      {isBanner && (
        <section className={classes.root}>
          <ContentWrapper>
            <CmsBanner
              url={url}
              pwaCallToActionLink={pwaCallToActionLink}
            />
          </ContentWrapper>
        </section>
      )}
    </>
  );
};
