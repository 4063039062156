import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  GoogleMap,
  Marker as GoogleMapMarker,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useReactiveVar } from '@apollo/client';
import { cx } from '@emotion/css';

import { IEcpMapProps } from './ecp-map.models';
import {
  GOOGLE_MAP_OPTIONS,
  CHILD_ZOOM,
  LIST_ZOOM,
  MAP_OPTIONS,
} from '../../../../../../../../constants/ecp-locator';
import { useStyles } from './ecp-map.styles';
import { IEcpStore } from '../../../../../../../../rest/ecp-locator';
import { getLatLng, getMarkerIcon, getCampaignMarkerIcon } from '../../../../../ecp-locator/utils';
import { setUserLocation } from '../../../../../../../../graphql/ecp-locator/ecp-locator.cache';
import {
  setIsLocatorIconsCampaign,
} from '../../../../../../../../graphql/configuration/configuration.cache';

export const EcpMap = ({
  ecpStores,
  storeDetail,
  isMapHidden,
  onSetStore,
  ...props
}: IEcpMapProps): JSX.Element => {
  const classes = useStyles();
  const userLocation = useReactiveVar(setUserLocation);
  const [initialMap, setInitialMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState(LIST_ZOOM);
  const [center, setCenter] = useState(getLatLng(userLocation));
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_OPTIONS.googleMapsApiKey,
    libraries: GOOGLE_MAP_OPTIONS.libraries,
  });
  const [markerCode, setMarkerCode] = useState<string>('');

  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  const onMarkerClick = (event: google.maps.MapMouseEvent, store: IEcpStore): void => {
    const centerCoords = getLatLng(store);

    // To change marker style to active.
    if (markerCode !== store.code) {
      setMarkerCode(store.code);
    }
    // To open store detail on marker click.
    onSetStore({
      ...store,
      rating: String(store.rating),
    });
    setCenter(centerCoords);
  };

  useEffect(() => {
    if (window.google) {
      const bounds = new window.google.maps.LatLngBounds();
      ecpStores.forEach((ecpStore) => bounds.extend(getLatLng(ecpStore)));
      setCenter(getLatLng(userLocation));
      if (initialMap) {
        initialMap.panToBounds(bounds);
      }
      setZoom(LIST_ZOOM - 1);
    }
  }, [ecpStores]);

  useEffect(() => {
    if (window.google && initialMap && !markerCode) {
      const mapCenter = new window.google.maps.LatLng({
        lat: Number(userLocation.latitude),
        lng: Number(userLocation.longitude),
      });
      initialMap.panTo(mapCenter);
    }
  }, [userLocation, initialMap]);

  useEffect(() => {
    if (window.google && storeDetail && storeDetail.latitude && storeDetail.longitude) {
      // Center marker after click on reduced store card.
      setCenter(getLatLng(storeDetail));
      setZoom(CHILD_ZOOM);

      // To change marker style to active.
      if (storeDetail.code && storeDetail.code !== markerCode) {
        setMarkerCode(storeDetail.code);
      }
    }
  }, [storeDetail]);

  const onLoad = useCallback((map: google.maps.Map) => {
    setInitialMap(map);
  }, [setInitialMap]);

  const onUnmount = useCallback(() => {
    setInitialMap(null);
  }, [setInitialMap]);

  return (
    <div
      className={cx(classes.mapWrapper,
        { [classes.mapHidden]: isMapHidden },
        { [classes.mapWrapperStoreDetail]: !!storeDetail },
      )}
      data-testid="google-map-wrapper"
    >
      {
        isLoaded && (
          <GoogleMap
            {...props}
            mapContainerClassName={classes.mapContainerStyle}
            center={center}
            options={MAP_OPTIONS}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {ecpStores.length && ecpStores.map((ecpStore) => (
              <GoogleMapMarker
                position={getLatLng(ecpStore!)}
                key={ecpStore.code}
                icon={isCampaignIcon ? getCampaignMarkerIcon(ecpStore, markerCode) : getMarkerIcon(ecpStore!, markerCode!)}
                onClick={onMarkerClick && ((event: google.maps.MapMouseEvent) => onMarkerClick(event, ecpStore!))}
                title={ecpStore && `${ecpStore.name} ${ecpStore.street}`}
                {...props}
              />
            ))}
          </GoogleMap>
        )
      }
    </div>
  );
};
