import { FeedbackType } from '../../survey-modal';

export interface IFeedbackToggleGroupProps {
  feedbackType: FeedbackType;
}

export enum PositiveFeedbackOptions {
  lensesUsageEducation = 'positive5_lenses_usage_education',
  staffProfessionalism = 'positive5_staff_professionalism',
  qualityVisionLenses = 'positive5_quality_vision_lenses',
  convenienceMakingAppointment  = 'positive5_convenience_making_appointment',
}

export enum NegativeFeedbackOptions {
  staffIncompetent = 'negative_staff_incompetent',
  lackOfLensesUsageEducation = 'negative_lack_of_lenses_usage_education',
  lowQualityVisionLenses = 'negative_low_quality_vision_lenses',
  longWaitingTimeAppointment = 'negative_long_waiting_time_appointment',
  complicatedMakingAppointment = 'negative_complicated_making_appointment',
}
