import { ReactElement, useMemo } from 'react';
import { sortBy } from 'lodash';

import {
  DiscountCard,
  FreepackCard,
  IDiscountCardProps,
  IFreepackCardProps,
} from '../bonus-cards';
import { BonusType, IBonusesData } from '../../../../../graphql/bonuses/models/get-bonuses.models';
import {
  checkLabelEnabled,
} from '../../my-lenses/my-lenses-fitted-cards-list/utils/check-label-enabled';
import { IBonusCards } from '../my-bonuses.models';
import { IPromotionCardProps, PromotionCard } from '../../user-activity-items/promotions';
import { PromotionType } from '../../../../../graphql/promotions/models/get-promotions.models';
import { replaceWelcomeVoucher } from '../utils/replace-welcome-voucher';
import { DISCOUNT_500, MINIMAL_DISCOUNT } from '../../../../../constants/points-discounts';

interface IBonusesParams {
  bonuses?: IBonusesData;
  balance?: number;
  hasFittings: boolean;
  hasPurchases: boolean;
}

export const useGetBonuses = ({
  hasFittings, bonuses, balance, hasPurchases,
}: IBonusesParams): IBonusCards => {
  const result = useMemo(() => {
    let discounts: ReactElement<IDiscountCardProps>[] = [];
    let freepacks: ReactElement<IFreepackCardProps>[] = [];
    let promotions: ReactElement<IPromotionCardProps>[] = [];

    if (bonuses && balance !== undefined) {
      discounts = bonuses.voucherByAuthId
        .filter(({ value, type }) => type === BonusType.discount
          && (hasFittings || value === MINIMAL_DISCOUNT || value === DISCOUNT_500))
        .map(({ value }, index) => (
          <DiscountCard
            id={index}
            isActivationMode={!hasFittings && balance >= value}
            isAvailable={balance >= value}
            balance={balance}
            value={value}
          />
        ));

      freepacks = sortBy(bonuses.voucherByAuthId
        .filter(({ type, brands }) => type === BonusType.freepack && hasFittings
          && brands.length), ['value'])
        .map(({ value, brands, packSize }, index) => (
          <FreepackCard
            id={index}
            brand={brands[0].product}
            productType={brands[0].productType}
            isAvailable={balance >= value}
            isActivationMode={!hasPurchases && balance >= value}
            packSize={packSize}
            noveltyLabel={brands[0].labels.find(checkLabelEnabled)?.name}
            balance={balance}
            value={value}
            fullName={brands[0].fullName}
          />
        ));

      // Add second welcome voucher if there is the first welcome voucher
      const promoBonuses = bonuses.getDynamicComponents;
      const firstVoucher = promoBonuses.find((
        promotion,
      ) => promotion.type === PromotionType.discountFirstPurchase);

      promotions = firstVoucher ? replaceWelcomeVoucher(promoBonuses)
        .map((promotion) => (
          <PromotionCard
            id={`${promotion?.data.title}${promotion?.data.endDate}`}
            promotion={promotion!}
            isAvailable={!(promotion?.type === PromotionType.discountSecondPurchase)}
            isFirstWelcomeVoucher={promotion?.type === PromotionType.discountFirstPurchase}
          />
        )) : promoBonuses.map((promotion) => (
        <PromotionCard
          id={`${promotion?.data.title}${promotion?.data.endDate}`}
          promotion={promotion}
          notClickable={promotion?.type === PromotionType.discountSecondPurchase}
        />
      ));
    }

    return {
      discounts,
      freepacks,
      promotions,
    };
  }, [
    bonuses,
    balance,
    hasFittings,
    hasPurchases,
  ]);

  return result;
};
