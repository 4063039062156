import { useModal } from 'mui-modal-provider';

import { IPromotionCardProps } from './promotion-card.models';
import { DateExpiredLabel } from '../../../../../common/date-expired-label';
import { BonusCard } from '../../../../../common/bonus-card';
import { Icon, IconSizes } from '../../../../../common/icon';
import { getPromoIconType } from '../../../../../../utils/promotions/get-promo-icon-type';
import {
  FirstVoucherModal,
} from '../../../my-bonuses/modals/welcome-voucher-modal/first-voucher-modal/first-voucher-modal';

export const PromotionCard = ({
  promotion, isFirstWelcomeVoucher, isAvailable = true, notClickable,
}: IPromotionCardProps): JSX.Element => {
  const { type, data: { title, shortDescription, endDate, externalLink } } = promotion;
  const { showModal } = useModal();

  const handleWelcomeVouchersClick = () => {
    if (isFirstWelcomeVoucher) {
      showModal(FirstVoucherModal, { endDate });
    }
  };

  return (
    <BonusCard
      data-testid="promotion-card"
      isAvailable={isAvailable}
      href={externalLink}
      cardTitle={title}
      description={shortDescription}
      onClick={handleWelcomeVouchersClick}
      bonusImage={(
        <Icon
          type={getPromoIconType(type, isAvailable)}
          size={IconSizes['3xl']}
        />
      )}
      footer={<DateExpiredLabel endDate={endDate as string} />}
      notClickable={notClickable}
    />
  );
};
