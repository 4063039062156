import React, {
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useReactiveVar, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { Order } from '../../../../user-activity-items';
import { IStepperImperativeHandleProps, IStepComponentProps } from '../../../../../../common/stepper';
import {
  IExternalState,
  IReplaceQuantity,
  IStepsState,
} from '../../order-create-page.models';
import {
  setOrderNumber,
  setOrderBonus,
  setOrderBonusId,
} from '../../../../../../../graphql/order/order.cache';
import { getLensesParams } from './utils/get-lenses-params';
import { CREATE_ORDER } from '../../../../../../../graphql/order/mutations/create-order';
import {
  useActionsInProgress,
} from '../../../../../../../graphql/preloader/actions/actions-in-progress';
import { getSubmitValues } from './utils/get-submit-values';
import { useBind } from '../../../../../../../hooks/use-bind';
import { useSnackbar } from '../../../../../../../hooks/use-snackbar';
import { IOrderSelectedBonus } from '../../../../../../../graphql/order/models/get-orders.models';
import { updateQuantity } from './utils/update-quantity';
import { IFormSubmitValues } from '../../../../../../common/form';
import { TFormValue } from '../add-lenses-step/add-lenses-step-form';
import { promiseErrorCallbacks } from '../../../../../../../utils/promise/set-promise-error-callbacks';
import { GET_ORDERS } from '../../../../../../../graphql/order/queries/get-orders';
import { getConcatFormValues } from './utils/get-concat-form-values';
import { getIndexedFormValues } from '../../utils/get-form-values';
import { scrollToTop } from '../../../../../../../utils/scroll-to-top';
import {
  BONUS_RULES,
  CUSTOM_FREE_PACK_IS_ABSENT,
  FREE_PACK_RULES,
  SOLUTIONS_OVERLIMITED,
  SOLUTIONS_NO_CONTRACT,
} from '../../../../../../../constants/errors';
import {
  CheckBonusModal,
} from '../../../../user-activity-items/order/bonuses/bonus/check-bonus-modal';
import { ConfirmStepTitle } from './confirm-step-title';
import { LeaveModal } from '../../leave-modal';
import { Button } from '../../../../../../common';
import { CallSupportModal } from '../../error-modals/call-support-modal';
import { SolutionsLimitModal } from '../../error-modals/solutions-limit-modal';
import { SolutionsContractModal } from '../../error-modals/solutions-contract-modal';

export const ConfirmStep = forwardRef<IStepperImperativeHandleProps, IStepComponentProps>(({
  stepsState,
  onGoNextSuccess,
  onGoBack,
  externalState,
  onGoFirst,
}, ref): JSX.Element => {
  const state = externalState as IExternalState;
  const innerState = stepsState as IStepsState;
  const { products, store } = state.isRepeatOrder ? state : innerState;

  const [createOrder] = useMutation(CREATE_ORDER, {
    refetchQueries: [{
      query: GET_ORDERS,
    }],
  });
  const { addActionInProgress, removeActionInProgress } = useActionsInProgress();
  const [orderProducts, setOrderProducts] = useState<TFormValue[]>(
    Object.values(products!.products as IFormSubmitValues) as TFormValue[],
  );
  const orderBonus = useReactiveVar(setOrderBonus);
  const orderBonusId = useReactiveVar(setOrderBonusId);

  const submitProducts = useMemo(() => getSubmitValues(orderProducts), [orderProducts]);

  const { enqueueSnackbar } = useSnackbar();
  const enqueueSnackbarBind = useBind(enqueueSnackbar);
  const intl = useIntl();

  const updateProductQuantity = (value: IReplaceQuantity) => {
    setOrderProducts(updateQuantity(orderProducts, value));
  };

  const handleBonusSelect = (id: string, bonus: IOrderSelectedBonus | null) => {
    setOrderBonusId(id);
    setOrderBonus(bonus);
  };

  const bonus = useMemo(() => orderBonus, [orderBonus]);
  const [isUnloadBlocked, setIsUnloadBlocked] = useState<boolean>(true);

  const { showModal } = useModal();

  const handleError = () => {
    setIsUnloadBlocked(true);
    if (promiseErrorCallbacks.anyError) {
      promiseErrorCallbacks.anyError();
    }
  };

  const handleChangeProducts = () => {
    innerState.isChange = true;
    onGoFirst!();
  };

  const handleChangeARL = () => {
    innerState.isChange = true;
    state.isChangeARL = true;

    onGoFirst!();
  };

  const handleStoreChange = () => {
    innerState.isChange = true;
    onGoBack();
  };

  const handleCreateOrder = async () => {
    setIsUnloadBlocked(false);

    if (!submitProducts.length) {
      setIsUnloadBlocked(true);
      enqueueSnackbarBind.current(
        intl.formatMessage(
          { id: 'order.create.confirm.error.noLenses' },
        ),
        { variant: 'error' },
      );
      return;
    }

    try {
      addActionInProgress();

      await createOrder({
        variables: {
          order: {
            storeCode: store?.code,
            products: submitProducts,
            voucherId: orderBonusId,
          },
        },
      }).then((orderData) => {
        if (orderData) {
          setOrderNumber(orderData.data.createOrder.systemId);
        }

        enqueueSnackbarBind.current(
          <FormattedMessage id="order.create.confirm.successMessage" />,
          { variant: 'success' },
        );

        onGoNextSuccess();
      }).catch((error: any) => {
        if (error.message.includes(BONUS_RULES) || error.message === FREE_PACK_RULES) {
          showModal(CheckBonusModal, {});
          setIsUnloadBlocked(true);
        } else if (error.message === CUSTOM_FREE_PACK_IS_ABSENT) {
          showModal(CallSupportModal, {
            onBlockUnload: setIsUnloadBlocked,
          });
        } else if (error.message.includes(SOLUTIONS_OVERLIMITED)) {
          showModal(SolutionsLimitModal, {
            onChangeARL: handleChangeARL,
          });
        } else if (error.message === SOLUTIONS_NO_CONTRACT) {
          showModal(SolutionsContractModal, {});
        } else if (promiseErrorCallbacks.anyError) {
          promiseErrorCallbacks.anyError();
          setIsUnloadBlocked(true);
        }
      });
    } catch {
      handleError();
    }
    removeActionInProgress();
  };

  useImperativeHandle(ref, () => ({
    goNext() {},
    goBack() {
      const confirmedProducts = getConcatFormValues(getIndexedFormValues(orderProducts));
      innerState.products = { products: confirmedProducts };
      innerState.store = store!;
      state.products = { products: confirmedProducts };
    },
    goFirst() {
      const confirmedProducts = getConcatFormValues(getIndexedFormValues(orderProducts));
      innerState.products = { products: confirmedProducts };
      innerState.store = store!;
      state.products = { products: confirmedProducts };
    },
  }));

  useEffect(() => scrollToTop(), []);

  return (
    <>
      {isUnloadBlocked ? <LeaveModal /> : null}
      <ConfirmStepTitle
        isRepeatOrder={state.isRepeatOrder}
        onGoBack={onGoBack}
      />
      <Order
        storeData={store!}
        products={getLensesParams(orderProducts)}
        isEditMode
        onStoreChange={handleStoreChange}
        onProductsChange={handleChangeProducts}
        onUpdateQuantity={updateProductQuantity}
        bonus={bonus ? bonus : undefined}
        onBonusSelect={handleBonusSelect}
        bonusId={orderBonusId}
      />
      <Button
        type="button"
        variant="contained"
        data-testid="stepper-go-next-button"
        fullWidth
        onClick={handleCreateOrder}
      >
        <FormattedMessage id="order.create.confirm.button.label" />
      </Button>
    </>
  );
});
