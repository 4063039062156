import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cx } from '@emotion/css';

import { useStyles } from './appointment.styles';
import { roundRating } from '../../../../utils/ecp-locator/round-rating';
import {
  Icon,
  IconSizes,
  IconTypes,
  Button,
} from '../../../common';
import { scrollToTop } from '../../../../utils/scroll-to-top';
import { IAppointmentProps } from './appointment.models';
import { Preloader } from '../../../common/preloader';
import { AppointmentMap } from './appointment-map/appointment-map';
import {
  AppointmentStatusType,
  AppointmentType,
} from '../../../../graphql/appointments/models/get-appointments.models';
import { isClinicStore } from '../../../../utils/appointment/define-store-types';
import { isAppointmentContentShow } from '../../../../utils/appointment/is-appoinment-content-show';
import { TechIssues } from '../../../common/tech-issues';

export const Appointment = ({
  appointments,
  isLoading,
  isError,
  onCancelClick,
  onAppointmentClose,
}: IAppointmentProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  const titleRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    titleRef.current?.focus();
    scrollToTop();
  }, []);

  const NoAppointments = () => (
    <div
      data-testid="appointment"
      className={classes.root}
    >
      <div
        data-testid="appointment-info-container"
        className={classes.appointmentInfo}
      />
      <div
        data-testid="appointment-map-container"
        className={classes.appointmentMap}
      >
        <AppointmentMap />
      </div>
    </div>
  );

  const renderContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return <TechIssues />;
    }

    const { allAppointmentsByAuthId: { edges } } = appointments!;

    if (edges.length) {
      const {
        node: {
          status,
          date,
          time,
          appointmentType,
          store: {
            displayName,
            address,
            workingHours,
            phone,
            storeType,
            storeSubType,
            latitude,
            longitude,
          },
          salesforceStoreInfo: {
            rating,
          },
        },
      } = edges[0];

      const isAppointmentShow = isAppointmentContentShow(date, status);

      const isClinic = isClinicStore(storeSubType || storeType);

      return (
        <div
          data-testid="appointment"
          className={classes.root}
        >
          {isAppointmentShow ? (
            <>
              <div
                data-testid="appointment-info-container"
                className={classes.appointmentInfo}
              >
                <h1
                  className={classes.title}
                  data-testid="appointment-title"
                >
                  <button
                    ref={titleRef}
                    className={classes.titleButton}
                    onClick={onAppointmentClose}
                    onKeyPress={onAppointmentClose}
                    tabIndex={0}
                    type="button"
                    data-testid="appointment-title-button"
                  >
                    {appointmentType === AppointmentType.controlVisit ? (
                      <FormattedMessage id="appointment.landing.title.controlVisit" />
                    ) : (
                      <FormattedMessage id="appointment.landing.title.fitting" />
                    )}
                    <span
                      className={classes.backButton}
                      aria-label={intl.formatMessage({ id: 'appointment.backButton.label' })}
                      data-testid="appointment-back"
                    >
                      <Icon
                        aria-hidden="true"
                        type={IconTypes.arrowLeft}
                        size={IconSizes.md}
                      />
                    </span>
                  </button>
                </h1>
                <div className={cx(classes.content, classes.bottomBorderSeparator)}>
                  <h2
                    className={classes.secondaryTitle}
                    data-testid="appointment-address-title"
                  >
                    {isClinic ? (
                      <FormattedMessage id="appointment.detail.address.title.clinic" />
                    ) : (
                      <FormattedMessage id="appointment.detail.address.title" />
                    )}
                  </h2>
                  <div className={classes.contentPartial}>
                    <p
                      data-testid="appointment-rating-bar"
                      className={classes.ratingWrapper}
                    >
                      {rating && (
                        <span
                          data-testid="appointment-rating"
                          className={cx(classes.pointSeparator, classes.rating)}
                        >
                        {roundRating(Number(rating))}
                      </span>
                      )}
                      <span
                        data-testid="appointment-type"
                      >
                        {isClinic ? (
                          <FormattedMessage id="appointment.storeSubtype.clinic" />
                        ) : (
                          <FormattedMessage id="appointment.storeSubtype.optics" />
                        )}
                      </span>
                    </p>
                    <p data-testid="appointment-name" className={cx(classes.street, classes.paragraph)}>
                      {displayName}
                    </p>
                    <p data-testid="appointment-address" className={cx(classes.street, classes.paragraph)}>
                      {address}
                    </p>
                  </div>
                  <div className={classes.contentPartial}>
                    <p
                      data-testid="appointment-hours"
                      className={cx(classes.paragraph, classes.hours)}
                    >
                      {workingHours}
                    </p>
                    <p className={classes.phoneWrapper}>
                      <a
                        data-testid="appointment-phone"
                        href={`tel:${phone}`}
                        className={cx(classes.phone, classes.paragraph)}
                      >
                        {phone}
                      </a>
                    </p>
                  </div>
                </div>
                <div className={classes.content}>
                  <div className={classes.contentPartial}>
                    <h2
                      className={classes.secondaryTitle}
                      data-testid="appointment-date-title"
                    >
                      {time ? (
                        <FormattedMessage id="appointment.detail.dateTime.title" />
                      ) : (
                        <FormattedMessage id="appointment.detail.date.title" />
                      )}
                    </h2>
                    <p className={cx(classes.paragraph, classes.date)}>
                      <span data-testid="date-partial">
                        {new Date(date).toLocaleDateString(intl.locale, {
                          day: 'numeric',
                          month: 'long',
                          year: 'numeric',
                        })}
                      </span>
                      {time && (
                        <span data-testid="time-partial">
                          {`, ${time}`}
                        </span>
                      )}
                    </p>
                  </div>
                  <div className={classes.contentPartial}>
                    <h2
                      className={classes.secondaryTitle}
                      data-testid="appointment-state-title"
                    >
                      <FormattedMessage id="appointment.detail.state.title" />
                    </h2>
                    <p className={cx(classes.paragraph, classes.state)}>
                      {status === AppointmentStatusType.unconfirmed && (
                        <FormattedMessage id="appointment.detail.state.unconfirmed" />
                      )}
                      {status === AppointmentStatusType.confirmed && (
                        <FormattedMessage id="appointment.detail.state.confirmed" />
                      )}
                    </p>
                  </div>
                </div>
                <div className={classes.cancelButtonWrapper}>
                  <Button
                    data-testid="appointment-cancel-button"
                    variant="outlined"
                    onClick={onCancelClick}
                  >
                    <FormattedMessage id="appointment.cancelButton.label" />
                  </Button>
                </div>
              </div>
              <div
                data-testid="appointment-map-container"
                className={classes.appointmentMap}
              >
                <AppointmentMap
                  latitude={Number(latitude)}
                  longitude={Number(longitude)}
                  storeType={storeSubType || storeType}
                />
              </div>
            </>
          ) : <NoAppointments />}
        </div>
      );
    }

    return <NoAppointments />;
  };

  return (
    <div data-testid="appointment-wrapper">
      {renderContent()}
    </div>
  );
};
