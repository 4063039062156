import { CmsBannerType } from '../../../../../common/cms-banner';
import { PromotionType } from '../../../../../../graphql/promotions/models/get-promotions.models';
import { IPromotion } from '../../../../../../graphql/promotions/models/get-promotions.models';

const isType = (data: IPromotion[], type: PromotionType): boolean => {
  return !!(data.find((promotion) => {
    return promotion.type === type;
  }));
};

export const getBannerType = (data: IPromotion[]) => {
  let bannerType: CmsBannerType | string = '';

  switch (true) {
    case isType(data, PromotionType.discountFirstPurchase):
      bannerType = CmsBannerType.userWithFirstWelcomeVoucher;
      break;
    case isType(data, PromotionType.discountSecondPurchase):
      bannerType = CmsBannerType.userWithSecondWelcomeVoucher;
      break;
    default:
      bannerType = CmsBannerType.userWithoutVouchers;
      break;
  }

  return bannerType;
};
