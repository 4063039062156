import { FormattedMessage, useIntl } from 'react-intl';

import { useStyles } from './my-lenses-fitted-card.styles';
import { Card } from '../../../../../../common/card';
import { Divider } from '../../../../../../common/divider';
import { LensesBrandLogo } from '../../../../../../common/lenses-brand-logo';
import { IMyLensesFittedCardProps } from './my-lenses-fitted-card.models';
import { LensesBrandType } from '../../../../../../../graphql/lenses/models/get-lenses.models';
import { InternalLinks } from '../../../../../../../constants/internal-links';
import { CardFooter } from './card-footer';
import { checkComplianceActive } from '../../../../../../../utils/user/check-compliance-active';
import { getPointsByBrand } from '../utils/get-points-by-brand';
import { getLatestEvent } from '../utils/get-latest-brand';
import { getBrandByPriority } from '../utils/get-brand-by-priority';
import { getBrandFullName } from '../../../../../../../utils/lenses/get-brand-full-name';
import { ReactNode } from 'react';
import { OutOfSalesLabel } from '../../../../out-of-sales/out-of-sales-label';

export const MyLensesFittedCard = ({
  lensesData, lensesProfileData, purchasesData, fittingsData,
}: IMyLensesFittedCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { compliancePeriodEndDate } = lensesProfileData;
  const isCompliancePeriod = !!compliancePeriodEndDate && checkComplianceActive(compliancePeriodEndDate);
  const loyaltyPoints = getPointsByBrand(lensesData);
  const latestLensesEvent = getLatestEvent(purchasesData, fittingsData);
  const brand = getBrandByPriority(latestLensesEvent);
  const isOneDateBrand = brand === LensesBrandType.oneDate;

  return (
    <Card
      to={InternalLinks.myLensesPage}
      className={classes.root}
      data-testid="my-lenses-preview-fitted"
    >
      <div className={classes.lensesContent}>
        <h3 className={classes.title}>
          <FormattedMessage
            id="myLensesPreview.title"
          />
        </h3>
        <div className={classes.lensesBrand}>
          <LensesBrandLogo
            className={classes.lensesBrandLogo}
            brand={brand as LensesBrandType}
          />
          <p
            className={classes.lensesBrandName}
            data-testid="my-lenses-preview-brand"
          >
            {brand && intl.formatMessage(
              { id: getBrandFullName(brand) },
              { sup: (chunks: ReactNode) => <sup>{chunks}</sup> },
            )}
          </p>
        </div>
        {isOneDateBrand && (
          <OutOfSalesLabel
            message={intl.formatMessage({
              id: 'lenses.1DATE.outOfSales.message',
            },
            {
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            },
            )}
            messageStyles={classes.messageStyles}
          />
        )}
      </div>
      <Divider />
      <CardFooter
        isCompliancePeriod={isCompliancePeriod}
        complianceDate={compliancePeriodEndDate}
        loyaltyPoints={loyaltyPoints}
        isOneDateBrand={isOneDateBrand}
      />
    </Card>
  );
};
