import React, { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Form, IFormImperativeHandleProps } from '../../../../../../common/form';
import { TextField } from '../../../../../../common/form/text-field';
import { FeedbackToggleGroup } from './feedback-toggle-group';
import { ISurveyFormProps } from './survey-form.models';
import { useStyles } from './survey-form.styles';

export const SurveyForm = forwardRef<IFormImperativeHandleProps, ISurveyFormProps>(({
  feedbackType,
  defaultValues,
  validationSchema,
  onSubmit,
}, ref): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Form
      ref={ref}
      defaultValues={defaultValues}
      validationSchema={validationSchema}
      validationMode="onChange"
      onSubmit={onSubmit}
    >
      <p className={classes.surveyTitle}>
        <FormattedMessage
          id={`survey.modal.${feedbackType}Feedback.title`}
        />
      </p>
      <FeedbackToggleGroup feedbackType={feedbackType} />
      <TextField
        name="comment"
        fullWidth
        multiline
        variant="outlined"
        placeholder={intl.formatMessage({ id: 'survey.modal.comment.placeholder' })}
      />
    </Form>
  );
});
