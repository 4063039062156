import { ChangeEvent } from 'react';
import { SchemaOf } from 'yup';

import { IModalProps } from '../../../../../../common/modal';
import { NegativeFeedbackOptions, PositiveFeedbackOptions } from '../survey-form/feedback-toggle-group';

export interface ISurveyModalProps extends IModalProps {
  fittingProps: IFittingProps;
}

export interface ISurveyModalViewProps extends IModalProps {
  rating: IRating;
  storeName: string;
  validationSchema: SchemaOf<Partial<Pick<IFormValues, 'comment'>>>;
  defaultFormValues: IFormValues;
  onSurveyResponse: (val: IFormValues) => void;
  onRatingChange: (event: ChangeEvent<{}>, value: IRating) => void;
  onSubmitButtonClick: () => void;
}

export interface IFittingProps {
  storeName: string;
  consumerId: string;
  sourceObjectId: string;
  updateFittings: (fittingId: string) => void;
}

export type IRating = number | null;

export enum FeedbackType {
  positive = 'positive',
  negative = 'negative',
}

export type IFormValues = {
  comment: string;
  feedback: PositiveFeedbackOptions[] | NegativeFeedbackOptions[];
};
