import React, {
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  GoogleMap,
  useJsApiLoader,
} from '@react-google-maps/api';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { cx } from '@emotion/css';
import { useReactiveVar } from '@apollo/client';

import {
  GOOGLE_MAP_OPTIONS,
  CHILD_ZOOM,
  MAP_OPTIONS,
  DEFAULT_CENTER,
} from '../../../../../constants/ecp-locator';
import { useStyles } from './appointment-map.styles';
import { getLatLng } from '../../ecp-locator/utils';
import { IAppointmentMapProps, MapCoordinates } from './appointment-map.models';
import {
  getCampaignMarkerIcon,
  getMarkerIcon,
} from '../utils/get-icon-from-type';
import { Marker } from '../../../../common/marker';
import { setIsLocatorIconsCampaign } from '../../../../../graphql/configuration/configuration.cache';

export const AppointmentMap = ({
  latitude,
  longitude,
  storeType,
  ...props
}: IAppointmentMapProps): JSX.Element => {
  const classes = useStyles();
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAP_OPTIONS.googleMapsApiKey,
    libraries: GOOGLE_MAP_OPTIONS.libraries,
  });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xl'));
  const [scrollY, setScrollY] = useState('');

  const handleWindowScroll = () => {
    if (window.scrollY > 120) {
      setScrollY('scrolled');
    } else {
      setScrollY('not-scrolled');
    }
  };

  const getBounds = ({ lat, lng }: MapCoordinates) => {
    if (lat && lng) {
      return getLatLng({ latitude: lat, longitude: lng });
    }

    return getLatLng(DEFAULT_CENTER);
  };

  useEffect(() => {
    if (isDesktop) {
      document.addEventListener('scroll', handleWindowScroll);
    }

    return () => document.removeEventListener('scroll', handleWindowScroll);
  }, []);

  const onLoad = useCallback((map: google.maps.Map) => {
    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(getBounds({ lat: latitude, lng: longitude }));
    map.panToBounds(bounds);
  }, []);

  const isCampaignIcon = useReactiveVar(setIsLocatorIconsCampaign);

  return (
    <div
      className={cx(classes.mapWrapper, {
        [classes.mapWrapperScrolled]: scrollY === 'scrolled',
      })}
      data-testid="google-map-wrapper"
    >
      {
        isLoaded && (
          <GoogleMap
            {...props}
            mapContainerClassName={classes.mapContainerStyle}
            center={getBounds({ lat: latitude, lng: longitude })}
            options={MAP_OPTIONS}
            zoom={CHILD_ZOOM}
            onLoad={onLoad}
          >
            {
              (latitude && longitude && storeType) && (
                <Marker
                  position={getBounds({ lat: latitude, lng: longitude })}
                  markerIcon={storeType && (isCampaignIcon ? getCampaignMarkerIcon(storeType) : getMarkerIcon(storeType))}
                />
              )
          }
          </GoogleMap>
        )
      }
    </div>
  );
};
