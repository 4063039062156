import { forwardRef, ReactNode, HTMLAttributes } from 'react';
import { cx } from '@emotion/css';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '../../../../../../common/button';
import { Modal, ResponsiveWidth } from '../../../../../../common/modal';
import { ISurveyModalViewProps } from './survey-modal.models';
import { useStyles } from './survey-modal.styles';
import { Rating } from '../../../../../../common/rating';
import { SurveyForm } from '../survey-form';
import { IFormImperativeHandleProps } from '../../../../../../common/form';
import { getFeedbacktype } from '../utils/get-feedback-type';

export const SurveyModal = forwardRef<IFormImperativeHandleProps, ISurveyModalViewProps>(({
  rating,
  storeName,
  validationSchema,
  defaultFormValues,
  onSurveyResponse,
  onRatingChange,
  onSubmitButtonClick,
  ...props
}, ref): JSX.Element => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Modal
      {...props}
      disableClosing
      responsiveWidth={ResponsiveWidth.lg}
      PaperProps={{ 'data-testid': 'after-fitting-survey' } as HTMLAttributes<HTMLDivElement>}
      title={(
        <div className={cx(classes.title, classes.container)}>
          {intl.formatMessage(
            { id: 'survey.modal.title' },
            {
              linebreak: <br />,
              sup: (chunks: ReactNode) => <sup>{chunks}</sup>,
            },
          )}
        </div>
      )}
      content={(
        <div className={cx(classes.content, classes.container)}>
          <p className={classes.ratingTitle}>
            <FormattedMessage
              id="survey.modal.rating.title"
              values={{
                linebreak: <br />,
                storeName,
              }}
            />
          </p>
          <Rating
            name="rating"
            className={classes.rating}
            value={rating}
            onChange={onRatingChange}
          />
          {rating && (
            <SurveyForm
              ref={ref}
              defaultValues={defaultFormValues}
              feedbackType={getFeedbacktype(rating)}
              validationSchema={validationSchema}
              onSubmit={onSurveyResponse}
            />
          )}
        </div>
      )}
      footer={(
        <>
          {rating && (
            <div className={classes.container}>
              <Button
                fullWidth
                variant="contained"
                onClick={onSubmitButtonClick}
                data-testid="send-fitting-survey-button"
              >
                <FormattedMessage
                  id="survey.modal.sendButton.label"
                />
              </Button>
            </div>
          )}
        </>
      )}
    />
  );
});
