import React from 'react';
import { cx } from '@emotion/css';

import { IHelpBlockProps } from './help-block.models';
import { Button } from '../../../../../common/button';

import { useStyles } from './help-block.styles';

export const HelpBlock = (props: IHelpBlockProps): JSX.Element => {
  const {
    title,
    description,
    content,
    action,
    hintTexts,
  } = props;

  const classes = useStyles();

  return (
    <div
      className={classes.section}
      data-testid="footer-help-block"
    >
      <h2
        className={cx(classes.text, classes.title)}
        data-testid="footer-help-title"
      >
        {title}
      </h2>
      {content}
      <p className={cx(classes.text, classes.description)}>
        {description}
      </p>
      <Button
        {...action}
        className={classes.button}
        variant="contained"
        data-testid="footer-help-action"
      />
      <p className={classes.hintText}>
        {hintTexts}
      </p>
    </div>
  );
};
