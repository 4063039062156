import React from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Checkbox } from '../../../../../../common/checkbox';
import { Icon, IconSizes, IconTypes } from '../../../../../../common/icon';
import { IReminderProps } from './reminder.models';
import { useStyles } from './reminder.styles';

export const Reminder = ({ isActive, onClick }: IReminderProps): JSX.Element => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const size = isTablet ? IconSizes.lg : IconSizes.md;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Checkbox
      data-testid="reminder-button"
      iconCheckbox
      checked={isActive}
      icon={(
        <Icon
          type={IconTypes.reminder}
          size={size}
        />
      )}
      checkedIcon={(
        <span className={classes.activeIcon} />
      )}
      onClick={onClick}
      aria-label={intl.formatMessage({ id: 'reminders.modal.create.title' })}
    />
  );
};
