import { ElementType } from 'react';
import { PaperProps } from '@mui/material';
import { TLinkHref } from '../../../models/link.models';

export enum CardTypes {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  bonus = 'bonus',
}

export interface IAdditionalProps {
  to?: TLinkHref;
  state?: unknown;
  component?: ElementType;
  type?: CardTypes;
  href?: string;
  target?: string;
}

export type TCardProps = PaperProps & Omit<IAdditionalProps, 'target'>;
