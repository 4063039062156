import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    gap: 3,
    fontSize: 45,
    [breakpoints.up('md')]: {
      gap: 8,
      fontSize: 68,
    },
  },
  icon: {
    fontSize: 'inherit',
    width: '1em',
    height: '1em',
  },
}));
