import React from 'react';
import { useReactiveVar } from '@apollo/client';

import { useSortingType } from '../../../../../graphql/ecp-locator/actions/set-stores-sorted';
import { sortingType } from '../../../../../graphql/ecp-locator/ecp-locator.cache';
import { ToggleButtonGroup } from '../../../../common/toggle-button-group';
import { useSortButtons } from './sort-buttons/use-sort-buttons';

export const EcpSort = (): JSX.Element => {
  const currentSortingType = useReactiveVar(sortingType);
  const { setSortingTypeToCache } = useSortingType();
  const buttons = useSortButtons();

  return (
    <ToggleButtonGroup
      fullWidth
      exclusiveExactly
      withoutMargins
      size="small"
      variant="outlined"
      options={buttons}
      value={currentSortingType}
      onChange={setSortingTypeToCache}
      exclusive
    />
  );
};
