import { IconTypes } from '../../components/common';
import { PromotionType } from '../../graphql/promotions/models/get-promotions.models';

export const getPromoIconType = (
  type: PromotionType, isAvailable?: boolean,
): IconTypes | undefined => {
  let iconType: IconTypes;

  switch (type) {
    case PromotionType.freepack:
      iconType = IconTypes.giftRoundedBlue;
      break;
    case PromotionType.giftCertificate:
      iconType = IconTypes.giftRoundedViolet;
      break;
    default:
      iconType = isAvailable
        ? IconTypes.percentRoundedBlue
        : IconTypes.percentRoundedDotted;
  }

  return iconType;
};
