import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { CustomBreakpoints } from '../../../../../../themes/default';

export const useStyles = makeStyles(({
  breakpoints,
  spacing,
  palette,
  borderRadiuses,
}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '16px 12px',
    border: `1px solid ${palette.grey['400']}`,
    borderRadius: borderRadiuses.md,
    [breakpoints.up('md')]: {
      padding: '20px 24px',
    },
  },
  logoContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gap: 10,
    height: 88,
    [breakpoints.up('md')]: {
      gap: 20,
    },
    '& label': {
      display: 'block',
    },
  },
  noveltyLabel: {
    marginTop: 20,
  },
  lensesBrandLogo: {
    height: 68,
  },
  eyeParamsContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    gap: spacing(1.5),
  },
  block: {
    display: 'block',
  },
  messageStyles: {
    maxWidth: 200,
    [breakpoints.between('xl', CustomBreakpoints.outOfSalesDesktopNarrow)]: {
      maxWidth: 120,
    },
  },
}));
