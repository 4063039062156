import {
  IPromotion,
  PromotionType,
} from '../../../../../../graphql/promotions/models/get-promotions.models';
import { sortByDateAsc } from '../../../../../../utils/date/sort-by-date';

export const getCampaignBannerId = (data: IPromotion[]): string => {
  const campaignPromotions = data
    .filter((promotion) => promotion.type === PromotionType.discount
      || promotion.type === PromotionType.giftCertificate
      || promotion.type === PromotionType.freepack);
  return campaignPromotions.length ? sortByDateAsc(campaignPromotions, 'startDate')[0].id.toLowerCase() : '';
};
