import { useIntl } from 'react-intl';

import { useStyles } from './promotions.styles';
import { IPromotionsProps } from './promotions.models';
import { ContentWrapper } from '../../content-wrapper';
import { Preloader } from '../../../../common/preloader';
import { PromotionsList } from './promotions-list';

export const Promotions = ({ promotionsData }: IPromotionsProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { data, loading, error } = promotionsData;

  const renderCard = () => {
    if (loading) {
      return <Preloader />;
    }

    if (error) {
      return null;
    }

    return (
      <PromotionsList promotions={data!.getDynamicComponents} />
    );
  };

  return (
    <section
      className={classes.root}
      data-testid="promotions"
      aria-label={intl.formatMessage({ id: 'promotions.title' })}
    >
      <ContentWrapper>
        {renderCard()}
      </ContentWrapper>
    </section>
  );
};
