import React, { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { IInternetShopsProps } from './internet-shops.models';
import { useStyles } from './internet-shops.styles';
import { PointsDescriptionCard } from './points-description-card';
import { ShopsList } from './shops-list';
import { Preloader } from '../../../../common/preloader';
import { Icon, IconSizes, IconTypes } from '../../../../common/icon';
import { Button } from '../../../../common/button';
import { scrollToTop } from '../../../../../utils/scroll-to-top';

export const InternetShops = ({
  shops, isFitted, isLoading, isError, onBackButtonClick,
}: IInternetShopsProps): JSX.Element => {
  const classes = useStyles();
  const titleRef = useRef<HTMLHeadingElement>(null);
  const intl = useIntl();

  useEffect(() => {
    titleRef.current?.focus();
    scrollToTop();
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return <Preloader />;
    }

    if (isError) {
      return null;
    }

    return (
      <>
        <PointsDescriptionCard
          isFitted={!!isFitted}
          onFitLensesClick={onBackButtonClick}
        />
        <ShopsList shops={shops!} />
      </>
    );
  };

  return (
    <div data-testid="internet-shops-page">
      <Button onClick={onBackButtonClick} color="info">
        <h1
          ref={titleRef}
          tabIndex={-1}
          className={classes.title}
          data-testid="internet-shops-title"
        >
          <FormattedMessage
            id="ecp.onlineShops.title"
          />
          <div
            className={classes.backButton}
            aria-label={intl.formatMessage({ id: 'ecp.backButton.label' })}
          >
            <Icon
              aria-hidden="true"
              type={IconTypes.arrowLeft}
              size={IconSizes.md}
            />
          </div>
        </h1>
      </Button>
      {renderContent()}
    </div>
  );
};
