import { useIntl } from 'react-intl';

import { Icon, IconTypes, IconSizes } from '../../../../../common';
import { useStyles } from './socials-networks.styles';

export const SocialsNetworks = (): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <ul
      className={classes.socialNetworksList}
    >
      <li>
        <a href={intl.formatMessage({ id: 'common.email.program' })} target="_blank">
          <Icon type={IconTypes.email} size={IconSizes.lg} />
        </a>
      </li>
      <li>
        <a href={intl.formatMessage({ id: 'common.telegram' })} target="_blank">
          <Icon type={IconTypes.telegram} size={IconSizes.lg} />
        </a>
      </li>
      <li>
        <a href={intl.formatMessage({ id: 'common.watsApp' })} target="_blank">
          <Icon type={IconTypes.whatsApp} size={IconSizes.lg} />
        </a>
      </li>
    </ul>
  );
};
