import { ReactNode, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from 'mui-modal-provider';

import { Card } from '../../../../../../../common/card';
import { IconTypes, LensesBrandLogo } from '../../../../../../../common';
import { EyeParameters } from './eye-parameters';
import { IconButton } from '../../../../../../../common/icon-button';
import { ILensesCardProps } from './lenses-card.models';
import { useStyles } from './lenses-card.styles';
import { getBrandFullName } from '../../../../../../../../utils/lenses/get-brand-full-name';
import { getEnding, getIsFourEnd } from '../../../../../../../../utils/get-ending';
import { DeleteLensesModal } from './delete-lenses-modal';
import { ProductQuantity } from './product-quantity';
import {
  LensesBrandType,
  SolutionsBrandType,
} from '../../../../../../../../graphql/lenses/models/get-lenses.models';
import { SolutionsBrandLogo } from '../../../../../../../common/solutions-brand-logo';

export const LensesCard = ({
  brand, packSize, eye, productQuantity, isEditMode, onUpdateQuantity, id,
}: ILensesCardProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const { showModal } = useModal();

  const handleUpdateProductQuantity = (value: number) => {
    onUpdateQuantity!({
      productQuantity: String(value),
      id: id!,
    });
  };

  const packSizeText = getIsFourEnd(Number(packSize)) ? (
    <FormattedMessage
      id="order.create.lensesNumber.unit.four.inBrackets"
      values={{
        nonBreakSpace: <>&nbsp;</>,
        value: packSize,
      }}
    />
  ) : (
    <FormattedMessage
      id="order.create.lensesNumber.unit.inBrackets"
      values={{
        nonBreakSpace: <>&nbsp;</>,
        value: packSize,
      }}
    />
  );

  const fullNameId = useMemo(() => {
    return getBrandFullName(brand);
  }, [brand]);

  const handleDeleteButtonClick = () => {
    showModal(DeleteLensesModal, {
      onDeleteConfirm: handleUpdateProductQuantity,
      brand,
    });
  };

  const isSolutionsBrand = brand === SolutionsBrandType.arl;

  return (
    <Card
      className={classes.root}
      data-testid="lenses-card"
    >
      {isSolutionsBrand ? (
        <span className={classes.imageSolutionsWrapper}>
          <SolutionsBrandLogo
            brand={brand as SolutionsBrandType}
            className={classes.imageSolutions}
          />
        </span>
      ) : (
        <LensesBrandLogo
          brand={brand as LensesBrandType}
          className={classes.image}
        />
      )}
      <div
        data-testid="lenses-card-content"
        className={classes.contentWrapper}
      >
        <h4
          className={classes.brand}
          data-testid="brand-wrapper"
        >
          <span data-testid="brand-name">
            {fullNameId && intl.formatMessage({
              id: fullNameId,
            }, {
              'sup': (chunks: ReactNode) => chunks,
            })}
          </span>
          {!isSolutionsBrand && (
            <span
              data-testid="pack-size"
              className={classes.packSize}
            >
              {packSizeText}
            </span>
          )}
        </h4>
        {!isSolutionsBrand && (
          <EyeParameters
            eye={eye}
          />
        )}
        {isSolutionsBrand && (
          <p
            data-testid="solutions-volume"
            className={classes.volume}
          >
            <FormattedMessage
              id="order.create.confirm.solutions.packageQuantity"
              values={{
                nonBreakSpace: <>&nbsp;</>,
                value: packSize,
              }}
            />
          </p>
        )}
        <div
          data-testid="card-footer"
          className={classes.footer}
        >
          {isEditMode ? (
            <ProductQuantity
              brand={brand}
              productQuantity={productQuantity as string}
              onUpdateQuantity={handleUpdateProductQuantity}
              onDeleteButtonClick={handleDeleteButtonClick}
            />
          ) : (
            <p
              data-testid="package-number"
              className={classes.packageNumber}
            >
              <FormattedMessage
                id="order.create.confirm.lenses.packageQuantity"
                values={{
                  value: productQuantity,
                  ending: getEnding(Number(productQuantity)),
                }}
              />
            </p>
          )}
          {isEditMode && (
            <IconButton
              aria-label={intl.formatMessage(
                { id: 'order.create.confirm.lenses.delete' },
                { brandName: fullNameId && intl.formatMessage({
                  id: fullNameId,
                }, {
                  'sup': (chunks: ReactNode) => chunks,
                }) },
              ) as string}
              iconProps={{
                type: IconTypes.bin,
              }}
              onClick={handleDeleteButtonClick}
              className={classes.deleteButton}
            />
          )}
        </div>
      </div>
    </Card>
  );
};
