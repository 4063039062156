import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { PromotionType } from '../../../../../graphql/promotions/models/get-promotions.models';
import { IPromotionParams } from '../../user-activity-items/promotions';

interface ISecondVoucherProps {
  title?: string | ReactNode;
}

const getSecondVoucher = ({
  title,
}: ISecondVoucherProps): IPromotionParams => ({
  type: PromotionType.discountSecondPurchase,
  data: {
    title,
    endDate: <FormattedMessage id="promotions.secondVoucher.endDate" />,
    shortDescription: <FormattedMessage id="promotions.secondVoucher.shortDescription" />,
  },
});

export const replaceWelcomeVoucher = (promotions: IPromotionParams[]) => {
  const firstVoucher = promotions.find((
    promotion,
  ) => promotion.type === PromotionType.discountFirstPurchase);
  const welcomeVouchers = [firstVoucher, getSecondVoucher(firstVoucher?.data!)];
  const promotionsNoWelcome = promotions.filter((
    promotion,
  ) => promotion.type !== PromotionType.discountFirstPurchase);

  return [...welcomeVouchers, ...promotionsNoWelcome];
};
