import React from 'react';
import { useModal } from 'mui-modal-provider';

import {
  AppointmentRequestButton as AppointmentRequestButtonView,
} from './appointment-request-button';
import { AppointmentRegistrationModal } from '../appointment-registration-modal';
import { AskLoginModal } from '../../../common/ask-login-modal';
import { appointmentSettings, IAppointmentSettingsProps } from '../../../common/appointment-settings';

const AppointmentRequestButtonComponent = ({
  isLoggedIn, appointments, isLoading, isError, updateAppointments,
}: IAppointmentSettingsProps): JSX.Element => {
  const { showModal } = useModal();
  const handleButtonClick = () => {
    if (isLoggedIn) {
      showModal(AppointmentRegistrationModal, { updateAppointments: updateAppointments! });
    } else {
      showModal(AskLoginModal, {
        description: 'appointment.loginRegister.modal.description',
      });
    }
  };

  return (
    <AppointmentRequestButtonView
      isLoggedIn={isLoggedIn}
      isLoading={isLoading}
      isError={isError}
      appointments={appointments}
      onClick={handleButtonClick}
    />
  );
};

export const AppointmentRequestButton = appointmentSettings(AppointmentRequestButtonComponent);
