import React from 'react';
import { useQuery } from '@apollo/client';

import { Promotions as PromotionsView } from './promotions';
import { IPromotions } from '../../../../../graphql/promotions/models/get-promotions.models';
import { GET_CURRENT_PROMOTIONS } from '../../../../../graphql/promotions/queries/get-promotions';

export const Promotions = (): JSX.Element => {
  const data = useQuery<IPromotions>(GET_CURRENT_PROMOTIONS);

  return (
    <PromotionsView promotionsData={data} />
  );
};
