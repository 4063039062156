import { gql } from '@apollo/client';

export const GET_CURRENT_PROMOTIONS = gql`
  query GetPromotions {
    getDynamicComponents {
      id
      type
      data {
        title
        shortDescription
        endDate
        externalLink
        startDate
      }
    }
  }
`;
