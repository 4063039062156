import { format, parse } from 'date-fns';

import { BACKEND_DATE_FORMAT, BACKEND_DATE_SHORT_FORMAT, UI_DATE_FORMAT } from '../../constants';
import { toDate } from './to-date';

export const convertStringToDate = (stringDate?: string | null): Date | null => (
  stringDate ? toDate(format(new Date(stringDate), BACKEND_DATE_FORMAT)) : null
);

export const convertShortStringToDate = (stringDate?: string | null): Date | null => (
  stringDate ? toDate(format(new Date(stringDate), BACKEND_DATE_SHORT_FORMAT)) : null
);

export const convertUIStringToDate = (stringDate?: string | null): Date | null => (
  stringDate ? toDate(parse(stringDate, UI_DATE_FORMAT, new Date())) : null
);
