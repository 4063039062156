import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles(({ breakpoints, fontSizes, fontWeights }: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    scrollMarginTop: '30px',
    [breakpoints.up('sm')]: {
      scrollMarginTop: '50px',
    },
    '&&': {
      border: 'none',
      borderRadius: 0,
      boxShadow: 'none',
    },
  },
  contentWrapper: {
    marginLeft: 16,
    [breakpoints.up('sm')]: {
      marginLeft: 20,
    },
  },
  brand: {
    position: 'relative',
    margin: '0 0 8px',
    fontSize: fontSizes.xs,
    lineHeight: 1.3,
    fontWeight: fontWeights.regular,
    scrollMarginTop: '70px',
    [breakpoints.up('sm')]: {
      scrollMarginTop: '100px',
      fontSize: fontSizes.sm,
    },
  },
  image: {
    objectFit: 'contain',
    width: 80,
    marginTop: 2,
    [breakpoints.up('sm')]: {
      width: 140,
    },
  },
  imageSolutionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: 80,
    marginTop: 2,
    [breakpoints.up('sm')]: {
      width: 140,
    },
  },
  imageSolutions: {
    objectFit: 'contain',
    width: 'auto',
    height: 74,
    [breakpoints.up('sm')]: {
      height: 90,
    },
  },
}));
